import { IGetTeamListResponse, ITeam, ITeamListService, ITeamRequest } from "./team.model";

import httpClient from "../../common/http/httpClient";

const TeamListService = (): ITeamListService => {
  return {
    getTeamList: (val: ITeamRequest): HttpPromise<IGetTeamListResponse> => {
      return httpClient
        .post<ITeam[]>(`${process.env.REACT_APP_API_BASE_URL}/api/team-management/get-teams-list`, ({data: val}))
        .then((res) => res);
    },
  };
};

export default TeamListService();
