import {
  IGetNonWorkHourResponse,
  INonWorkHour,
  INonWorkHourService,
} from "./team-nonwork-hour.model";

import httpClient from "../../../common/http/httpClient";

const NonWorHourService = (): INonWorkHourService => {
  return {
    getNonWorkHourList: (reqObject: any): HttpPromise<IGetNonWorkHourResponse> => {
      return httpClient
        .post<INonWorkHour[]>(
          `${process.env.REACT_APP_API_BASE_URL}/api/team-management/get-week-non-work-hours`,
          { data: reqObject }
        )
        .then((res) => res);
    },
  };
};

export default NonWorHourService();
