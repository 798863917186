import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { searchCraftAPI } from "../../../api/service";

const INITIAL_STATE = {
  response: null,
  loading: false,
  error: null,
  status: "idle",
} as any;

export const _searchRecord = createAsyncThunk(
  "api/craftmanagement/searchRecordSlice",
  searchCraftAPI.searchRecord
);

export const searchRecordSlice = createSlice({
  name: "searchRecordSlice",
  initialState: INITIAL_STATE,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(_searchRecord.pending, (state) => {
        state.loading = true;
        state.status = "loading";
      })
      .addCase(_searchRecord.fulfilled, (state, action) => {
        state.loading = false;
        state.error = null;
        state.response = action.payload.data;
        state.status = "succeeded";
      })
      .addCase(_searchRecord.rejected, (state, action) => {
        state.loading = false;
        state.response = null;
        state.error = action.payload;
        state.status = "failed";
      });
  },
});
