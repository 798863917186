import Header from "../../shared/header/Header";
import pageStyles from "./Page.module.scss";

interface PageProps {
  children: React.ReactNode | React.ReactNode[];
}

const Page = ({ children }: PageProps) => {
    return (
      <>
        <Header></Header>
        <div className={pageStyles.page}>{children}</div>
      </>
    );
};

export default Page;
