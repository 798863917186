import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { searchResourceAPI } from "../../../../api/service";

export interface IResourceListSlice {
  resourceList: any | null;
  loading: boolean;
  error: any;
  status: string;
}

const INITIAL_STATE = {
  resourceList: [],
  loading: false,
  error: null,
  status: "idle",
} as IResourceListSlice;

export const getResourceList = createAsyncThunk(
  "resource/getResourceList",
  searchResourceAPI.getResourceList
);

const resourceListSlice = createSlice({
  name: "resourceList",
  initialState: INITIAL_STATE,
  reducers: {
    resetReourceList: (state, action) => {
      state.status = "idle";
      state.resourceList = null;
      state.loading = false;
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getResourceList.pending, (state) => {
        state.loading = true;
        state.status = "loading";
      })
      .addCase(getResourceList.fulfilled, (state, action) => {
        state.loading = false;
        state.error = null;
        state.resourceList = action.payload.data;
        state.status = "succeeded";
      })
      .addCase(getResourceList.rejected, (state, action) => {
        state.loading = false;
        state.resourceList = null;
        state.error = action.payload;
        state.status = "failed";
      });
  },
});

export default resourceListSlice.reducer;
export const { resetReourceList } = resourceListSlice.actions;
