import { createSlice } from "@reduxjs/toolkit";


const initialState = {
  craftAction: {
    mode: '', craftResponse: { isError: false, message: '' }, templateInfo: '', refreshRequired: true
  }
};

export const globalCraftSlice = createSlice({
  name: "globalCraft",
  initialState,
  reducers: {
    setCraftAction: (state, action) => {
      state.craftAction = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const { setCraftAction } = globalCraftSlice.actions;

export default globalCraftSlice.reducer;
