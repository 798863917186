import {
  IGetResourceWeekHoursResponse,
  IGetResourceWeekHoursRequest,
  IResourceNonWorkHour,
  IResourceWeekHoursService,
} from "./resource-week-hour.model";

import httpClient from "../../../common/http/httpClient";

const ResourceWeekHoursService = (): IResourceWeekHoursService => {
  return {
    getResourceWeekHours: (reqObject: IGetResourceWeekHoursRequest): HttpPromise<IGetResourceWeekHoursResponse[]> => {
      return httpClient
        .post<IGetResourceWeekHoursResponse>(
          `${process.env.REACT_APP_API_BASE_URL}/api/resource-managemen/get-resource-week-hours`,
          { data: reqObject }
        )
        .then((res) => res);
    },
  };
};

export default ResourceWeekHoursService();
