import { createSlice } from "@reduxjs/toolkit";
import {BaseIntegrationRecordItem} from "./integrationRecord.model"


const initialState = {
  documentRecordData: {
    data: {} as BaseIntegrationRecordItem,
    recordTabId: -1
  }
}

export const documentRecordSlice = createSlice({
  name: "setDocumentRecord",
  initialState,
  reducers: {
    setDocumentRecord: (state, action) => {
      state.documentRecordData = action.payload;
    },
  },
});
// Action creators are generated for each case reducer function
export const { setDocumentRecord } = documentRecordSlice.actions;

export default documentRecordSlice.reducer;
