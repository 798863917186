import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { API_RESPONSE_STATUS } from "../../../constants/common.constant";
import crewApi from "../../../api/crew/crew-resource.api"

export interface ICrewDataState {
    response: any;
    loading: boolean;
    error: any;
    status: string;
  }

const INITIAL_STATE = {
  response: null,
  loading: false,
  error: null,
  status: "idle"
} as ICrewDataState;

export const _getCrewData = createAsyncThunk(
  "crew/getCrewData",
  crewApi.getDefaultTeamMember
);

export const getCrewTeamDataSlice = createSlice({
  name: "getCrewData",
  initialState: INITIAL_STATE,
  reducers: {
  },
  extraReducers: (builder) => {
    builder
      .addCase(_getCrewData.pending, (state) => {
        state.loading = true;
        state.status = API_RESPONSE_STATUS.LOADING;
      })
      .addCase(_getCrewData.fulfilled, (state, action) => {
        state.loading = false;
        state.error = null;
        state.response = action.payload.data;
        state.status = API_RESPONSE_STATUS.SUCCESS;
      })
      .addCase(_getCrewData.rejected, (state, action) => {
        state.loading = false;
        state.response = null;
        state.error = action.payload;
        state.status = API_RESPONSE_STATUS.FAILED;
      })
  },
});