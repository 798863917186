import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { userManagementAPI } from "../../../api/service";
import { IUserManagementTableData, IUserRole } from "../../../api/admin-module/user-management.model";

export interface IUserManagementListSlice {
  userManagementTableData: IUserManagementTableData[];
  roles: IUserRole[] | null;
  loading: boolean;
  searchLoading: boolean;
  error: any;
  status: string;
}

const INITIAL_STATE = {
  userManagementTableData: [],
  roles: null,
  loading: false,
  searchLoading: false,
  error: null,
  status: "idle",
} as IUserManagementListSlice;

export const searchUserManagementTable = createAsyncThunk(
  "userManagement/searchUserManagementTable",
  userManagementAPI.searchUserManagementTable
);

export const saveUser = createAsyncThunk(
  "userManagement/saveUser",
  userManagementAPI.saveUser
);

export const deleteUser = createAsyncThunk(
  "UserManagement/DeleteUser",
  userManagementAPI.deleteUser
);

export const getRoles = createAsyncThunk(
  "userManagement/getRoles",
  userManagementAPI.getRoles
);

export const getRolesForRequestAccess = createAsyncThunk(
  "userManagement/getRolesForRequestAccess",
  userManagementAPI.getRolesForRequestAccess
);

export const getApplicationUsers = createAsyncThunk(
  "userManagement/getapplicationusers",
  userManagementAPI.getApplicationUsers
);

const userManagementSlice = createSlice({
  name: "userManagement",
  initialState: INITIAL_STATE,
  reducers: {
  },
  extraReducers: (builder) => {
    builder
      .addCase(searchUserManagementTable.pending, (state) => {
        state.searchLoading = true;
        state.status = "loading";
      })
      .addCase(searchUserManagementTable.fulfilled, (state, action) => {
        state.searchLoading = false;
        state.error = null;
        state.userManagementTableData = action.payload.data.searchResults;
        state.status = "succeeded";
      })
      .addCase(searchUserManagementTable.rejected, (state, action) => {
        state.searchLoading = false;
        state.userManagementTableData = [];
        state.error = action.payload;
        state.status = "failed";
      })
      .addCase(saveUser.pending, (state) => {
        state.loading = true;
        state.status = "loading";
      })
      .addCase(saveUser.fulfilled, (state) => {
        state.loading = false;
        state.error = null;
        state.status = "succeeded";
      })
      .addCase(saveUser.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
        state.status = "failed";
      })
      .addCase(getRoles.pending, (state) => {
        state.loading = true;
        state.status = "loading";
      })
      .addCase(getRoles.fulfilled, (state, action) => {
        state.loading = false;
        state.error = null;
        state.roles = action.payload.data;
        state.status = "succeeded";
      })
      .addCase(getRoles.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
        state.roles = null;
        state.status = "failed";
      })
      .addCase(getApplicationUsers.pending, (state) => {
        state.loading = true;
        state.status = "loading";
      })
      .addCase(getApplicationUsers.fulfilled, (state, action) => {
        state.loading = false;
        state.error = null;
        state.roles = action.payload.data;
        state.status = "succeeded";
      })
      .addCase(getApplicationUsers.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
        state.roles = null;
        state.status = "failed";
      });
  },
});

export default userManagementSlice.reducer;