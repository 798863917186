import { createSlice } from "@reduxjs/toolkit";
import { ICraftRecord } from "../../../api/craft/model/record.model";

const initialState = {
  craftRecordItem: {} as ICraftRecord}

export const craftRecordAddSlice = createSlice({
  name: "craftRecordAdd",
  initialState,
  reducers: {
    addCraftRecord: (state, action) => {
      state.craftRecordItem = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const { addCraftRecord } = craftRecordAddSlice.actions;

export default craftRecordAddSlice.reducer;
