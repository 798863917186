import { AxiosResponse } from "axios";
import httpClient from "../../common/http/httpClient";
import { IDefaultResponse } from "../common/common.model";
import { IGetOrderOperationRequest, IGetOrderOperationResponse, IMaintenanceOrderAutoPopulatedRequest, IMaintenanceOrderAutoPopulatedResponse, IMaintenanceOrderTooltipResponse, IOperationService, IUpdateOperationRequest, IUpdateOperationResponse } from "./operation.model";

const OperationService = (): IOperationService => {
  return {
    getOperationOrder: (
      request: IGetOrderOperationRequest
    ): HttpPromise<IGetOrderOperationResponse> => {
      return httpClient
        .post<IGetOrderOperationResponse>(
          `${process.env.REACT_APP_API_BASE_URL}/api/maintenance-order/get-maintenance-operation-report`,
          { data: request }
        )
        .then((res: AxiosResponse<IGetOrderOperationResponse, any>) => res);
    },
    getMaintenanceOrderAutoPopulated: (request: IMaintenanceOrderAutoPopulatedRequest): HttpPromise<IMaintenanceOrderAutoPopulatedResponse> => {
      return httpClient
        .post<IMaintenanceOrderAutoPopulatedResponse>(`${process.env.REACT_APP_API_BASE_URL}/api/maintenance-order/get-auto-populated-fields`, { data: request })
        .then((res: AxiosResponse<IMaintenanceOrderAutoPopulatedResponse, any>) => res);
    },
    getMaintenanceOrderTooltip: (params: "certificate" | "equipment" | "permit"): HttpPromise<IMaintenanceOrderTooltipResponse> => {
      return httpClient
        .get<IMaintenanceOrderTooltipResponse>(`${process.env.REACT_APP_API_BASE_URL}/api/maintenance-order/get-maintenance-order-tooltip/${params}`)
        .then((res: AxiosResponse<IMaintenanceOrderTooltipResponse, any>) => res);
    },
    updateOperation: (request: IUpdateOperationRequest): HttpPromise<IUpdateOperationResponse> => {
      return httpClient
        .post<IUpdateOperationResponse>(`${process.env.REACT_APP_API_BASE_URL}/api/maintenance-order/update-operation`, { data: request })
        .then((res: AxiosResponse<IUpdateOperationResponse, any>) => res);
    }
  }
};

export default OperationService();
