import { combineReducers } from "redux";
import { GetAvailableHoursList } from "./getAvailableHoursByDateAndTeamIdSlice";
import { RemoveResourceScheduleSlice } from "./removeResourceScheduleSlice";
import { SaveSchedulingSlice } from "./saveSchedulingSlice";
import { ScheduleCalendarSlice } from "./schedulingCalendarSlice";
import { ScheduleChartSlice } from "./schedulingChartSlice";
import { planningChartSlice } from "./planningChartSlice";

export const schedulingRootReducer = combineReducers({
    availableHoursListScheduling: GetAvailableHoursList.reducer,
    removeResourceScheduling: RemoveResourceScheduleSlice.reducer,
    saveScheduling: SaveSchedulingSlice.reducer,
    schedulingCalendar: ScheduleCalendarSlice.reducer,
    schedulingChart: ScheduleChartSlice.reducer,
    schedulingPlanningChart: planningChartSlice.reducer
});