import { createSlice } from "@reduxjs/toolkit";
import {BaseIntegrationRecordItem} from "./integrationRecord.model"


const initialState = {
  craftRecordData: {
    data: {} as BaseIntegrationRecordItem,
    recordTabId: -1
  }
}

export const craftRecordSlice = createSlice({
  name: "setCraftRecord",
  initialState,
  reducers: {
    setCraftRecord: (state, action) => {
      state.craftRecordData = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const { setCraftRecord } = craftRecordSlice.actions;

export default craftRecordSlice.reducer;
