import { createSlice } from "@reduxjs/toolkit";
import {BaseIntegrationRecordItem} from "./integrationRecord.model"


const initialState = {
  ptwRecordData: {
    data: {} as BaseIntegrationRecordItem,
    recordTabId: -1
  }
}

export const ptwRecordSlice = createSlice({
  name: "setPtwRecord",
  initialState,
  reducers: {
    setPtwRecord: (state, action) => {
      state.ptwRecordData = action.payload;
    },
  },
});
// Action creators are generated for each case reducer function
export const { setPtwRecord } = ptwRecordSlice.actions;

export default ptwRecordSlice.reducer;
