import {
  IScheduleChartsService,
  IScheduleChartRequest,
  IScheduleChartResponse,
  IWeeklyPlanTooltipResponse
} from "./scheduling-charts.model";

import httpClient from "../../common/http/httpClient";
import { AxiosResponse } from "axios";

const ScheduleChartsService = (): IScheduleChartsService => {
  return {
    getScheduleChart: (_objData:IScheduleChartRequest): HttpPromise<IScheduleChartResponse> => {
      return httpClient
        .post<IScheduleChartResponse>(`${process.env.REACT_APP_API_BASE_URL}/api/scheduler/get-weekly-plan-chart`,{data:_objData})
        .then((res: AxiosResponse<IScheduleChartResponse, any>) => res);
    },

    getWeeklyPlanTooltip: (_objData:IScheduleChartRequest): HttpPromise<IWeeklyPlanTooltipResponse> => {
      return httpClient
        .post<IWeeklyPlanTooltipResponse>(`${process.env.REACT_APP_API_BASE_URL}/api/scheduler/get-weekly-plan-tooltip`,{data:_objData})
        .then((res: AxiosResponse<IWeeklyPlanTooltipResponse, any>) => res);
    },
  };
};

export default ScheduleChartsService();
