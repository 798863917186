import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { ITeamResource } from "../../../api/team-resource/team-resource-list.model";
import { teamInternalListAPI } from "../../../api/service";


export interface ITeamInternalListSlice {
  teamInternalList: ITeamResource[] | null;
  loading: boolean;
  error: any;
  status: string;
}

const INITIAL_STATE = {
  teamInternalList: null,
  loading: false,
  error: null,
  status: "idle",
} as ITeamInternalListSlice;

export const getTeamsInternalList = createAsyncThunk(
  "team/getTeamInternalList",
  teamInternalListAPI.getTeamInternalList
);

const teamInternalListSlice = createSlice({
  name: "teamInternalList",
  initialState: INITIAL_STATE,
  reducers: {
  },
  extraReducers: (builder) => {
    builder
      .addCase(getTeamsInternalList.pending, (state) => {
        state.loading = true;
        state.status = "loading";
      })
      .addCase(getTeamsInternalList.fulfilled, (state, action) => {
        state.loading = false;
        state.error = null;
        state.teamInternalList = action.payload.data;
        state.status = "succeeded";
      })
      .addCase(getTeamsInternalList.rejected, (state, action) => {
        state.loading = false;
        state.teamInternalList = null;
        state.error = action.payload;
        state.status = "failed";
      });
  },
});

export default teamInternalListSlice.reducer;

export const {  } = teamInternalListSlice.actions;
