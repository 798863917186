import { AxiosResponse } from "axios";
import httpClient from "../../common/http/httpClient";
import {
  ISearchTaskRequest,
  ISearchTaskResponse,
  ITechnicianBoardService,
  IUpdateWorkHourRequest,
  IGetTechnicianPlanningRequest,
  IUpdateWorkHourResponse,
  IGetTechnicianPlanningResponse,
  IGetTechnicianWorkHourRequest,
  IGetTechnicianWorkHourResponse,
  ITaskDetailResponse,
  ITaskRefreshRequest,
  IGetAllTechnicianResponse,
} from "./technician-board.model";
import { IDefaultResponse } from "../common/common.model";

const technicianBoardService = (): ITechnicianBoardService => {
  return {
    searchTask: (
      request: ISearchTaskRequest
    ): HttpPromise<ISearchTaskResponse> => {
      return httpClient
        .post<ISearchTaskResponse>(
          `${process.env.REACT_APP_API_BASE_URL}/api/technician/search-task`,
          { data: request }
        )
        .then((res: AxiosResponse<ISearchTaskResponse, any>) => res);
    },
    updateWorkHour: (
      reqObject: IUpdateWorkHourRequest
    ): HttpPromise<IUpdateWorkHourResponse> => {
      return httpClient
        .post<IUpdateWorkHourRequest>(
          `${process.env.REACT_APP_API_BASE_URL}/api/technician/update-work-hour`,
          { data: reqObject }
        )
        .then((res) => res);
    },
    getTechnicianPlanning: (
      reqObject: IGetTechnicianPlanningRequest
    ): HttpPromise<IGetTechnicianPlanningResponse> => {
      return httpClient
        .post<IGetTechnicianPlanningRequest>(
          `${process.env.REACT_APP_API_BASE_URL}/api/technician/get-technician-planning`,
          { data: reqObject }
        )
        .then((res) => res);
    },
    getTechnicianWorkHour: (
      reqObject: IGetTechnicianWorkHourRequest
    ): HttpPromise<IGetTechnicianWorkHourResponse> => {
      return httpClient
        .post<IGetTechnicianWorkHourRequest>(
          `${process.env.REACT_APP_API_BASE_URL}/api/technician/get-technician-work-hour`,
          { data: reqObject }
        )
        .then((res) => res);
    },
    getTaskDetail: (workOrderId: string): HttpPromise<ITaskDetailResponse> => {
      return httpClient
        .get<any>(
          `${process.env.REACT_APP_API_BASE_URL}/api/technician/task-details/${workOrderId}`
        )
        .then((res) => res);
    },
    refreshTaskDetail: (
      reqObject: ITaskRefreshRequest
    ): HttpPromise<IDefaultResponse> => {
      return httpClient
        .put<ITaskRefreshRequest>(
          `${process.env.REACT_APP_API_BASE_URL}/api/technician/task-details/refresh-status`,
          { data: reqObject }
        )
        .then((res) => res);
    },
    getAllTechnician(): HttpPromise<IGetAllTechnicianResponse> {
      return httpClient
        .get(`${process.env.REACT_APP_API_BASE_URL}/api/technician`)
        .then((res) => res.data);
    },
  };
};

export default technicianBoardService();
