import {
  IGetTeamHourResponse,
  ITeamHour,
  ITeamHourService,
} from "./team-default-hour.model";

import httpClient from "../../common/http/httpClient";

const TeamHourService = (): ITeamHourService => {
  return {
    getTeamHourList: (reqObject: any): HttpPromise<IGetTeamHourResponse> => {
      return httpClient
        .post<ITeamHour[]>(
          `${process.env.REACT_APP_API_BASE_URL}/api/team-management/get-team-default-hours`,
          { data: reqObject }
        )
        .then((res) => res);
    },
  };
};

export default TeamHourService();
