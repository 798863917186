import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { API_RESPONSE_STATUS } from "../../../constants/common.constant";
import ptwService from "../../../api/ptw/ptw.api";
import { ISearchPtwDocumentResponse } from "../../../api/ptw/ptw.model";

interface ISearchPTWState {
  responseData: ISearchPtwDocumentResponse | null;
  loading: boolean;
  error: any;
  status: string;
}

const INITIAL_STATE = {
  responseData: null,
  loading: false,
  error: null,
  status: "idle"
} as ISearchPTWState;

export const _searchPTW = createAsyncThunk(
  "ptw/searchDocument",
  ptwService.searchPtwDocument
);

export const searchPTWDocumentSlice = createSlice({
  name: "searchPTWDocument",
  initialState: INITIAL_STATE,
  reducers: {
  },
  extraReducers: (builder) => {
    builder
      .addCase(_searchPTW.pending, (state) => {
        state.loading = true;
        state.status = API_RESPONSE_STATUS.LOADING;
      })
      .addCase(_searchPTW.fulfilled, (state, action) => {
        state.loading = false;
        state.error = null;
        state.responseData = action.payload.data;
        state.status = API_RESPONSE_STATUS.SUCCESS;
      })
      .addCase(_searchPTW.rejected, (state, action) => {
        state.loading = false;
        state.responseData = null;
        state.error = action.payload;
        state.status = API_RESPONSE_STATUS.FAILED;
      })
  },
});