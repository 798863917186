import { combineReducers } from "@reduxjs/toolkit";
import selectDocumentReducer from "./selectDocumentSlice";
import documentActionReducer from "./myGovernanceGlobalActionSlice";
import { searchDocumentSlice } from "./searchDocumentSlices";
import { getAssignedDocumentSlice } from "./getAssignedDocumentSlices";
import { assignDocumentSlice } from "./assignDocumentSlices";

export const myGovernanceRootReducer = combineReducers({
  selectDocumentRecord: selectDocumentReducer,
  myGovernanceAction: documentActionReducer,
  searchDocument: searchDocumentSlice.reducer,
  searchDocumentByWo: getAssignedDocumentSlice.reducer,
  assignDocument: assignDocumentSlice.reducer
});