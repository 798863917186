import {
  IGetTeamInternalListResponse,
  ITeamInternalListService,
} from "./team-internal-list.model";

import httpClient from "../../common/http/httpClient";
import { ITeamResource } from "./team-resource-list.model";

const TeamInternalListService = (): ITeamInternalListService => {
  return {
    getTeamInternalList: (reqObj: {teamId:string}): HttpPromise<IGetTeamInternalListResponse> => {
      return (
        httpClient
          .post<ITeamResource[]>(`${process.env.REACT_APP_API_BASE_URL}/api/scheduler/get-internal-team-resources`,{data:reqObj})
          .then((res) => res)
      );
    },
  };
};

export default TeamInternalListService();
