import { createSlice } from "@reduxjs/toolkit";
import { AbstractDataModel, IActivityItem } from "../../../api/hep/hep-screen-model";

export interface IHepContext {
    activityId?: string,
    activity: IActivityItem | null,
    activityList: IActivityItem[],
    requestId: string,
    stage: number,
    status: string,
    data?: AbstractDataModel
}
const INITIAL_STATE = {
    data: {
        activityId: "",
        activity: null,
        activityList: [],
        //requestId: "",
        requestId: 'e8a565bc-ed6f-4154-a646-cf991682332e',
        stage: 0,
        status: "",
        data: [] as AbstractDataModel
    } as IHepContext
}


export const cachedRetrievehepContextSlice = createSlice({
    name: "cachedRetrieveHepContext",
    initialState: INITIAL_STATE,
    reducers: {
        setCachedRetrieveContext: (state, action) => {
            state.data = action.payload;
        },
    },
});

// Action creators are generated for each case reducer function
export const { setCachedRetrieveContext } = cachedRetrievehepContextSlice.actions;

export default cachedRetrievehepContextSlice.reducer;
