import { AxiosResponse } from "axios";
import httpClient from "../../common/http/httpClient";
import { IExportResponse, IExportAssignmentExcelRequest } from "./export.model";

const exportService = (): IExportResponse => {
    return {
        exportAssignmentExcel: (request: IExportAssignmentExcelRequest): HttpPromise<any> => {
            return httpClient
                .post(
                    `${process.env.REACT_APP_API_BASE_URL}/api/export-excel-function/download-excel`,
                    { data: request },
                    'blob'
                )
                .then((res: AxiosResponse<any, any>) => res)
        },
    }
}

export default exportService();
