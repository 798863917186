import httpClient from "../../common/http/httpClient";
import { ICrewTabService, IGetTeamMember, ISearchCrewMemberByString, ISearchCrewMemberResponse, ITeamResource } from "./crew-resource.model";

const CrewTabService = (): ICrewTabService => {
    return {
        getDefaultTeamMember: (request: IGetTeamMember) : HttpPromise<ITeamResource[]> => {
            return httpClient
            //   .post<any>("/local-json/default-team-member.json")
              .post(`${process.env.REACT_APP_API_BASE_URL}/api/scheduler/get-crew-tab-data`,  {data:request})
              .then((res) => res);
          },
        searchTemMeber: (request: ISearchCrewMemberByString) : HttpPromise<ISearchCrewMemberResponse> => {
            return httpClient
            //   .post<any>("/local-json/search-team-crew.json")
              .post<any>(`${process.env.REACT_APP_API_BASE_URL}/api/resource-management/search-crew`,  {data:request})
              .then((res) => res);
          },
    }
};

export default CrewTabService();