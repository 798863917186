import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { schedulingCalendarAPI } from "../../../api/service";

export interface IScheduleCalendarSlice {
  saveResponse:any;
  loading: boolean;
  error: any;
  status: string;
}

const INITIAL_STATE = {
  saveResponse: null,
  loading: false,
  error: null,
  status: "idle",
} as IScheduleCalendarSlice;

export const saveScheduleCalendar = createAsyncThunk(
  "scheduling/saveScheduleCalendar",
  schedulingCalendarAPI.saveScheduleCalendar
);

export const SaveSchedulingSlice = createSlice({
  name: "saveScheduleCalendar",
  initialState: INITIAL_STATE,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(saveScheduleCalendar.pending, (state) => {
        state.loading = true;
        state.status = "loading";
      })
      .addCase(saveScheduleCalendar.fulfilled, (state, action) => {
        state.loading = false;
        state.error = null;
        state.saveResponse = action.payload.data;
        state.status = "succeeded";
      })
      .addCase(saveScheduleCalendar.rejected, (state, action) => {
        state.loading = false;
        state.saveResponse = null;
        state.error = action.payload;
        state.status = "failed";
      });
  },
});

