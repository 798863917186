
import craftApi from "../../../api/craft/management/craft.api";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

const INITIAL_STATE = {
    response: null,
    loading: false,
    error: null,
    status: "idle",
  } as any;

export const _assignCraftRecord = createAsyncThunk(
    "api/scheduler/assignCraftRecord",
    craftApi.assignCraftRecord
);

export const assignCraftRecordSlice = createSlice({
    name: "assignCraftRecordSlice",
    initialState: INITIAL_STATE,
    reducers: {},
    extraReducers: (builder) => {
      builder
        .addCase(_assignCraftRecord.pending, (state) => {
          state.loading = true;
          state.status = "loading";
        })
        .addCase(_assignCraftRecord.fulfilled, (state, action) => {
          state.loading = false;
          state.error = null;
          state.response = action.payload.data;
          state.status = "succeeded";
        })
        .addCase(_assignCraftRecord.rejected, (state, action) => {
          state.loading = false;
          state.response = null;
          state.error = action.payload;
          state.status = "failed";
        });
    },
  });
