import {
  IGetTeamResourceListResponse,
  ITeamResource,
  ITeamResourceListService,
} from "./team-resource-list.model";

import httpClient from "../../common/http/httpClient";

const TeamResourceListService = (): ITeamResourceListService => {
  return {
    getTeamResourceList: (reqObj: {teamId:string}): HttpPromise<IGetTeamResourceListResponse> => {
      return (
        httpClient
          .post<ITeamResource[]>(`${process.env.REACT_APP_API_BASE_URL}/api/scheduler/get-current-team-resources`,{data:reqObj})
          .then((res) => res)
      );
    },
  };
};

export default TeamResourceListService();
