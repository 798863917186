import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { ITeamResource } from "../../../api/team-resource/team-resource-list.model";
import { teamResourceListAPI } from "../../../api/service";


export interface ITeamResourceListSlice {
  teamResourceList: ITeamResource[] | null;
  loading: boolean;
  error: any;
  status: string;
}

const INITIAL_STATE = {
  teamResourceList: null,
  loading: false,
  error: null,
  status: "idle",
} as ITeamResourceListSlice;

export const getTeamsResourceList = createAsyncThunk(
  "team/getTeamsResourceList",
  teamResourceListAPI.getTeamResourceList
);

const teamResourceListSlice = createSlice({
  name: "teamResourceList",
  initialState: INITIAL_STATE,
  reducers: {
  },
  extraReducers: (builder) => {
    builder
      .addCase(getTeamsResourceList.pending, (state) => {
        state.loading = true;
        state.status = "loading";
      })
      .addCase(getTeamsResourceList.fulfilled, (state, action) => {
        state.loading = false;
        state.error = null;
        state.teamResourceList = action.payload.data;
        state.status = "succeeded";
      })
      .addCase(getTeamsResourceList.rejected, (state, action) => {
        state.loading = false;
        state.teamResourceList = null;
        state.error = action.payload;
        state.status = "failed";
      });
  },
});

export default teamResourceListSlice.reducer;

export const {  } = teamResourceListSlice.actions;
