import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { schedulingCalendarAPI } from "../../../api/service";
import { IScheduleCalendar } from "../../../api/scheduling-calendar/scheduling-calendar.model";

export interface IScheduleCalendarSlice {
  woCalendarList: IScheduleCalendar[] | null;
  loading: boolean;
  error: any;
  status: string;
}

const INITIAL_STATE = {
  woCalendarList: null,
  loading: false,
  error: null,
  status: "idle",
  schedulingCharts: null,
  chartLoading: false
} as IScheduleCalendarSlice;

export const getScheduleCalendarList = createAsyncThunk(
  "scheduling/getScheduleCalendarList",
  schedulingCalendarAPI.getScheduleCalendarList
);

export const ScheduleCalendarSlice = createSlice({
  name: "schedulingCalendar",
  initialState: INITIAL_STATE,
  reducers: {
    resetWoCalendarList: (state) => {
      state.woCalendarList = null;
      state.status = "succeeded";
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getScheduleCalendarList.pending, (state) => {
        state.loading = true;
        state.status = "loading";
      })
      .addCase(getScheduleCalendarList.fulfilled, (state, action) => {
        state.loading = false;
        state.error = null;
        state.woCalendarList = [...action.payload.data];
        state.status = "succeeded";
      })
      .addCase(getScheduleCalendarList.rejected, (state, action) => {
        state.loading = false;
        state.woCalendarList = null;
        state.error = action.payload;
        state.status = "failed";
      });
  },
});

export const { resetWoCalendarList } = ScheduleCalendarSlice.actions;
