import { PublicClientApplication } from "@azure/msal-browser";

const msalConfig = {
  auth: {
    clientId: process.env.REACT_APP_CLIENT_ID,
    authority:
      "https://login.microsoftonline.com/" +
      process.env.REACT_APP_TENANT_ID +
      "/",
    redirectUri: process.env.REACT_APP_REDIRECT_URL, 
    navigateToLoginRequestUrl: true,
  },
  cache: {
    cacheLocation: "sessionStorage",
    storeAuthStateInCookie: false,
    secureCookies: false,
  },
  system: {
    windowHashTimeout: 60000,
    iframeHashTimeout: 6000,
    loadFrameTimeout: 0,
  },
};

const msalInstance =
  await PublicClientApplication.createPublicClientApplication(msalConfig);

await msalInstance.handleRedirectPromise().then((response) => {
  if (response) {
    sessionStorage.setItem("userAccount", JSON.stringify(response.account));
    sessionStorage.setItem("userEmail", response.account.username);
    sessionStorage.setItem("accountId", response.account.localAccountId);
    sessionStorage.setItem("userName", response.account.name);
    sessionStorage.setItem("loginState", true);
    sessionStorage.setItem("id_token", response.idToken);
    sessionStorage.setItem("userId", response.uniqueId);
  }
});

export async function login() {
  var loginRequest = {
    scopes: ["User.Read", "Profile", "Email"],
  };
  const accounts = msalInstance.getAllAccounts();
  if (accounts.length === 0) {
    msalInstance.loginRedirect(loginRequest);
  }
}

export async function logout() {
  msalInstance.logoutRedirect();
}
