import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { operationAPI} from "../../../api/service"
import { IGetOrderOperationResponse } from "../../../api/operation/operation.model";
import { API_RESPONSE_STATUS } from "../../../constants/common.constant";

export interface IFetchingOperationOrder {
  workorerList: IGetOrderOperationResponse;
  loading: boolean;
  error: any;
  status: string;
}

const INITIAL_STATE = {
  workorerList: {},
  loading: false,
  error: null,
  status: "idle",
} as IFetchingOperationOrder;

export const fetchOperationOrder = createAsyncThunk(
  "operation/fetchOperation",
  operationAPI.getOperationOrder
);


const fetchOperationOrderSlice = createSlice({
  name: "operation",
  initialState: INITIAL_STATE,
  reducers: {
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchOperationOrder.pending, (state) => {
        state.loading = true;
        state.status = API_RESPONSE_STATUS.LOADING;
      })
      .addCase(fetchOperationOrder.fulfilled, (state, action) => {
        state.loading = false;
        state.error = null;
        state.status = API_RESPONSE_STATUS.SUCCESS;
        state.workorerList = action.payload.data;
      })
      .addCase(fetchOperationOrder.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
        state.status = API_RESPONSE_STATUS.FAILED;
      })
  },
});

export default fetchOperationOrderSlice;