import { AxiosResponse } from "axios";
import httpClient from "../../common/http/httpClient";
import { IDefaultResponse } from "../common/common.model";
import {
  IAssignRequestBody,
  IDeleteAssignedRequestBody,
  IPtwService,
  ISearchRequestBody,
  ISearchPtwDocumentResponse,
  IPtwDocument,
} from "./ptw.model";

const ptwService = (): IPtwService => {
  return {
    searchPtwDocument: (
      request: ISearchRequestBody
    ): HttpPromise<ISearchPtwDocumentResponse> => {
      return httpClient
        .post<ISearchPtwDocumentResponse>(
          `${process.env.REACT_APP_API_BASE_URL}/api/eptw-documents/search`,
          { data: request }
        )
        .then((res: AxiosResponse<ISearchPtwDocumentResponse, any>) => res);
    },
    getPtwDocumentByWorkOrder: (
      workOrderId: string
    ): HttpPromise<IPtwDocument[]> => {
      return httpClient
        .get<IPtwDocument[]>(
          `${process.env.REACT_APP_API_BASE_URL}/api/eptw-documents/get-by-work-order/${workOrderId}`
        )
        .then((res: AxiosResponse<IPtwDocument[], any>) => res);
    },
    deleteAssignedPtwDocument: (
      request: IDeleteAssignedRequestBody
    ): HttpPromise<IDefaultResponse> => {
      return httpClient
        .delete<IDefaultResponse>(
          `${process.env.REACT_APP_API_BASE_URL}/api/eptw-documents/delete-assigned-document`,
          { data: request }
        )
        .then((res: AxiosResponse<IDefaultResponse, any>) => res);
    },
    assignPtwDocument: (
      request: IAssignRequestBody
    ): HttpPromise<IDefaultResponse> => {
      return httpClient
        .post<IDefaultResponse>(
          `${process.env.REACT_APP_API_BASE_URL}/api/eptw-documents/assign-to-work-order`,
          { data: request }
        )
        .then((res: AxiosResponse<IDefaultResponse, any>) => res);
    },
  };
};

export default ptwService();
