import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { orderAPI } from "../../api/service";
import { IAvailableHours, IGetAllOrderResponse } from "../../api/maintenance-order/order.model";

export interface IOrderSlice {
  orderResponse: IGetAllOrderResponse | null;
  availableHours: IAvailableHours[] | null;
  loading: boolean;
  error: any;
  status: string;
  loadingHours: boolean;
  updateOrderLoading: boolean;
}

const INITIAL_STATE = {
  orderResponse: null,
  availableHours: null,
  loading: false,
  error: null,
  status: "idle",
  loadingHours: false,
  updateOrderLoading: false
} as IOrderSlice;

export const getAllOrders = createAsyncThunk(
  "order/getAllOrders",
  orderAPI.getAllOrders
);

export const getAllAvailableHours = createAsyncThunk(
  "order/getAllAvailableHours",
  orderAPI.getAllAvailableHours
);

export const getFilteredOrders = createAsyncThunk(
  "order/getFilteredOrders",
  orderAPI.getFilteredOrders
);

export const createOrderSchedule = createAsyncThunk(
  "order/createOrderSchedule",
  orderAPI.createOrderSchedule
);

export const updateOrderSchedule = createAsyncThunk(
  "order/updateOrderSchedule",
  orderAPI.updateOrderSchedule
);

const orderSlice = createSlice({
  name: "order",
  initialState: INITIAL_STATE,
  reducers: {  
  },
  extraReducers: (builder) => {
    builder
    .addCase(getFilteredOrders.pending, (state) => {
      state.loading = true;
      state.status = "loading";
    })
    .addCase(getFilteredOrders.fulfilled, (state, action) => {
      state.loading = false;
      state.error = null;
      state.orderResponse = action.payload.data;
      state.status = "succeeded";
    })
    .addCase(getFilteredOrders.rejected, (state, action) => {
      state.loading = false;
      state.orderResponse = null;
      state.error = action.payload;
      state.status = "failed";
    })
    .addCase(getAllAvailableHours.pending, (state) => {
      state.loadingHours = true;
      state.status = "loading";
    })
    .addCase(getAllAvailableHours.fulfilled, (state, action) => {
      state.loadingHours = false;
      state.error = null;
      state.availableHours = action.payload.data;
      state.status = "succeeded";
    })
    .addCase(getAllAvailableHours.rejected, (state, action) => {
      state.loadingHours = false;
      state.availableHours = null;
      state.error = action.payload;
      state.status = "failed";
    })
    .addCase(createOrderSchedule.pending, (state) => {
      state.status = "loading";
    })
    .addCase(createOrderSchedule.fulfilled, (state, action) => {
      state.status = "succeeded";
    })
    .addCase(createOrderSchedule.rejected, (state, action) => {
      state.status = "failed";
    })
    .addCase(updateOrderSchedule.pending, (state) => {
      state.updateOrderLoading = true;
      state.status = "loading";
    })
    .addCase(updateOrderSchedule.fulfilled, (state, action) => {
      state.updateOrderLoading = false;
      state.status = "succeeded";
    })
    .addCase(updateOrderSchedule.rejected, (state, action) => {
      state.updateOrderLoading = false;
      state.status = "failed";
    });
    
  },
});

export default orderSlice.reducer;
