import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { searchCraftAPI } from "../../../api/service";

const INITIAL_STATE = {
  response: null,
  loading: false,
  error: null,
  status: "idle",
} as any;

export const _deleteRecord = createAsyncThunk(
  "api/craftmanagement/deleteRecordSlice",
  searchCraftAPI.deleteRecord
);

export const deleteRecordSlice = createSlice({
  name: "deleteRecordSlice",
  initialState: INITIAL_STATE,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(_deleteRecord.pending, (state) => {
        state.loading = true;
        state.status = "loading";
      })
      .addCase(_deleteRecord.fulfilled, (state, action) => {
        state.loading = false;
        state.error = null;
        state.response = action.payload.data;
        state.status = "succeeded";
      })
      .addCase(_deleteRecord.rejected, (state, action) => {
        state.loading = false;
        state.response = null;
        state.error = action.payload;
        state.status = "failed";
      });
  },
});
