import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { planningChartAPI, teamListAPI } from "../../api/service";
import { ITeam } from "../../api/teams/team.model";

export interface ITeamListSlice {
  teamList: ITeam[] | null;
  loading: boolean;
  error: any;
  status: string;
}

const INITIAL_STATE = {
  teamList: null,
  loading: false,
  error: null,
  status: "idle",
} as ITeamListSlice;

export const getTeamsList = createAsyncThunk(
  "team/getTeamList",
  teamListAPI.getTeamList
);

const teamListSlice = createSlice({
  name: "teamList",
  initialState: INITIAL_STATE,
  reducers: {
  },
  extraReducers: (builder) => {
    builder
      .addCase(getTeamsList.pending, (state) => {
        state.loading = true;
        state.status = "loading";
      })
      .addCase(getTeamsList.fulfilled, (state, action) => {
        state.loading = false;
        state.error = null;
        state.teamList = action.payload.data;
        state.status = "succeeded";
      })
      .addCase(getTeamsList.rejected, (state, action) => {
        state.loading = false;
        state.teamList = null;
        state.error = action.payload;
        state.status = "failed";
      });
  },
});

export default teamListSlice.reducer;

export const {  } = teamListSlice.actions;
