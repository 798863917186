import { createAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { API_RESPONSE_STATUS } from "../../../constants/common.constant";
import ptwService from "../../../api/ptw/ptw.api";
import { IPtwDocument } from "../../../api/ptw/ptw.model";

interface IGetAssignedPTWState {
  responseData: IPtwDocument[] | [];
  loading: boolean;
  error: any;
  status: string;
}

const INITIAL_STATE = {
  responseData: [],
  loading: false,
  error: null,
  status: "idle"
} as IGetAssignedPTWState;

const INITIAL_STATE_2 = {
  responseData: [],
  loading: false,
  error: null,
  status: "idle"
} as IGetAssignedPTWState;

export const _getAssignedPTW = createAsyncThunk(
  "ptw/getAssignedPTW",
  ptwService.getPtwDocumentByWorkOrder
);

export const _assignPtwDocument = createAsyncThunk(
  "ptw/assignPtwDocument",
  ptwService.assignPtwDocument
);

export const _deletePTW = createAction<string>('/ptw/getAssignedPTW/deletePTW')
export const _addPTW = createAction<IPtwDocument>('/ptw/getAssignedPTW/addPTW')

export const getAssignedPTW = createSlice({
  name: "getAssignedPTW",
  initialState: INITIAL_STATE,
  reducers: {
  },
  extraReducers: (builder) => {
    builder
      .addCase(_getAssignedPTW.pending, (state) => {
        state.loading = true;
        state.status = API_RESPONSE_STATUS.LOADING;
      })
      .addCase(_getAssignedPTW.fulfilled, (state, action) => {
        state.loading = false;
        state.error = null;
        state.responseData = action.payload.data;
        state.status = API_RESPONSE_STATUS.SUCCESS;
      })
      .addCase(_getAssignedPTW.rejected, (state, action) => {
        state.loading = false;
        state.responseData = [];
        state.error = action.payload;
        state.status = API_RESPONSE_STATUS.FAILED;
      })
      .addCase(_deletePTW, (state, action) => {
        const updatedData = state.responseData.filter(item => item.id !== action.payload);
        state.responseData = updatedData;
      })
      .addCase(_addPTW, (state, action) => {
        const updatedData = [...state.responseData]
        updatedData.push(action.payload)
        state.responseData = updatedData;
      })
  },
});

export const assignPtwDocument = createSlice({
  name: "assignPtwDocument",
  initialState: INITIAL_STATE_2,
  reducers: {
  },
  extraReducers: (builder) => {
    builder
      .addCase(_assignPtwDocument.pending, (state) => {
        state.loading = true;
        state.status = API_RESPONSE_STATUS.LOADING;
      })
      .addCase(_assignPtwDocument.fulfilled, (state, action) => {
        state.loading = false;
        state.error = null;
        state.responseData = action.payload.data;
        state.status = API_RESPONSE_STATUS.SUCCESS;
      })
      .addCase(_assignPtwDocument.rejected, (state, action) => {
        state.loading = false;
        state.responseData = [];
        state.error = action.payload;
        state.status = API_RESPONSE_STATUS.FAILED;
      })
  },
});