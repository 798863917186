import { IPlanningChartService, IGetAllPlanningChartResponse, IPlanningChart, ISchedulerChartScrollingRequest, ISchedulerChartScrollingResponse, ISchedulerChartData } from "./planning-chart.model";

import httpClient from "../../common/http/httpClient";
import { AxiosResponse } from "axios";

const PlanningChartService = (): IPlanningChartService => {
  return {
    getAllPlanningCharts: (): HttpPromise<IGetAllPlanningChartResponse> => {
      return httpClient
        .get<IPlanningChart[]>("/local-json/chartList.json")
        .then((res: AxiosResponse<IPlanningChart[], any>) => res);
    },
    getSchedulerChartScrolling: (request: ISchedulerChartScrollingRequest): HttpPromise<ISchedulerChartScrollingResponse>=>{
      return httpClient
      .post<ISchedulerChartData[]>(`${process.env.REACT_APP_API_BASE_URL}/api/maintenance-order/get-weekly-plan-chart`, {data:request})
      .then((res: AxiosResponse<ISchedulerChartData[], any>) => res);
    }
  };
};

export default PlanningChartService();
