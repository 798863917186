import { IOpu, ICommonService } from "./common.model";
import HttpClient from "../../common/http/httpClient";

const commonService = (): ICommonService => {
  return {
    getOpuList: () => {
      return HttpClient
        .get(`${process.env.REACT_APP_API_BASE_URL}/api/operating-unit-management/get-opu-list`)
        .then((res)=> res);
    },
  }
};

export default commonService();