import {
  IGetTeamExternalListResponse,
  ITeamExternal,
  ITeamExternalListService,
} from "./team-external-list.model";

import httpClient from "../../common/http/httpClient";

const TeamExternalListService = (): ITeamExternalListService => {
  return {
    getTeamExternalList: (searchText:string): HttpPromise<IGetTeamExternalListResponse> => {
      return (
        httpClient
          .get<ITeamExternal[]>(`${process.env.REACT_APP_API_BASE_URL}/api/resource-management/search-resource?name=${searchText}`)
          .then((res) => res)
      );
    },
  };
};

export default TeamExternalListService();
