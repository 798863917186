import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  list: [] as any,
};

export const resourceDetailsSlice = createSlice({
  name: "resourceDetails",
  initialState,
  reducers: {
    setResourceList: (state, action) => {
      state.list = action.payload;
    }
  },
});

// Action creators are generated for each case reducer function
export const { setResourceList } =
  resourceDetailsSlice.actions;

export default resourceDetailsSlice.reducer;

