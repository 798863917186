import { Link } from "react-router-dom";
import { PATH } from "../../../constants/path.constant";
import { getUserName } from "../../../utils/user.utils";
import "./Header.scss";

const Header = () => {
  return (
    <header className="work-header">
      <div className="d-flex flex-wrap align-items-center justify-content-center justify-content-lg-start">
        <div className="p-2 pt-4">
          <Link to={PATH.LANDINGPAGE}>
            <div className="d-flex flex-column work-logo-txt">
              <div>
                <span className="work_txt_head">WORK</span>
              </div>
              <div>
                <span className="work_txt_det">
                  Workload Optimizer & Record Keeping
                </span>
              </div>
            </div>
          </Link>
        </div>

        <div className="ms-auto p-2 bd-highlight">
          <div className="pull-right navbar-text">
            <span className="heder-user-text">{getUserName()}</span>&nbsp;
          </div>
        </div>
      </div>
    </header>
  );
};

export default Header;
