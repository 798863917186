import { AssignDocumentRequest, DocumentDataResponse, IMyGovernanceService, SearchDocumentByWORequest, SearchDocumentRequest } from "./my-governance.model";
import httpClient from "../../common/http/httpClient";
import { IDefaultResponse } from "../common/common.model";

const MyGovernanceService = (): IMyGovernanceService => {
    return {
        searchDocument: (request: SearchDocumentRequest) : HttpPromise<DocumentDataResponse> => {
            return httpClient
              .post<any>(`${process.env.REACT_APP_API_BASE_URL}/api/my-governance/search-document`, {data:request})
              .then((res) => res);
          },
          getAssignedDocument: (request: SearchDocumentByWORequest) : HttpPromise<DocumentDataResponse> => {
            return httpClient
              .post<any>(`${process.env.REACT_APP_API_BASE_URL}/api/my-governance/search-document-mapping-wo`, {data:request})
              .then((res) => res);
          },
          deleteDocumentByWOId: (documentId: string) : HttpPromise<IDefaultResponse> => {
            return httpClient
              .delete<any>(`${process.env.REACT_APP_API_BASE_URL}/api/my-governance/delete-document?id=${documentId}`)
              .then((res) => res);
          },
          assignDocumentToWO: (request: AssignDocumentRequest) : HttpPromise<IDefaultResponse> => {
            return httpClient
              .post<any>(`${process.env.REACT_APP_API_BASE_URL}/api/my-governance/create-update-my-governance-mapping`, {data:request})
              .then((res) => res);
          },


    }
};

export default MyGovernanceService();