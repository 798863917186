import { createAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { searchCraftAPI } from "../../../api/service";
import { IRecordList } from "../../../api/craft/model/record-list.model";

const INITIAL_STATE = {
    response: [],
    loading: false,
    error: null,
    status: "idle",
} as any;

export const listRecords = createAsyncThunk(
    "api/craftmanagement/listRecordSlice",
    searchCraftAPI.listRecords
);

export const _deleteRecord = createAction<string>('/craftmanagement/listRecordSlice/deleteRecord')
export const _addRecord = createAction<IRecordList>('/craftmanagement/listRecordSlice/addRecord')

export const listRecordSlice = createSlice({
    name: "listRecordSlice",
    initialState: INITIAL_STATE,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(listRecords.pending, (state) => {
                state.loading = true;
                state.status = "loading";
            })
            .addCase(listRecords.fulfilled, (state, action) => {
                state.loading = false;
                state.error = null;
                state.response = action.payload.data.filter((i: { currentStatus: string; }) => i.currentStatus != "Abort Task");
                state.status = "succeeded";
            })
            .addCase(listRecords.rejected, (state, action) => {
                state.loading = false;
                state.error = null;
                state.response = action.payload;
                state.status = "failed";
            })
            .addCase(_deleteRecord, (state, action) => {
                const updatedData = state.response.filter((item: { serialNumber: string; }) => item.serialNumber !== action.payload);
                state.response = updatedData;
            })
            .addCase(_addRecord, (state, action) => {
                const updatedData = [...state.response]
                if (action.payload.serialNumber) {
                    updatedData.push(action.payload)
                }
                state.response = updatedData;
            })
    },
});