import { combineReducers } from "@reduxjs/toolkit";
import workAssignmentActionReducer from "./workAssignmentActionSlice";
import workAssignmentSelectRecordReducer from "./workAssignmentSelectRecordSlice";
import craftRecordReducer from "./craftTabRecordSlide";
import documentRecordReducer from "./myGovernanceTabRecordSlide";
import ptwRecordReducer from "./ptwTabRecordSlide";

export const integrationRecordActionRootReducer = combineReducers({
    selectRecord: workAssignmentSelectRecordReducer,
    waAction:  workAssignmentActionReducer, 
    chooseCraftRecord: craftRecordReducer,
    chooseDocumentRecord: documentRecordReducer,
    choosePtwRecord: ptwRecordReducer,
  });