import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { planningChartAPI } from "../../../api/service";
import {  ISchedulerChartData } from "../../../api/planning-charts/planning-chart.model";
import { DocumentDataResponse } from "../../../api/maintenance-order/my-governance.model";
import myGovernanceApi from "../../../api/maintenance-order/my-governance.api";
import { API_RESPONSE_STATUS } from "../../../constants/common.constant";

export interface ISearchDocumentSlice {
  documentData: DocumentDataResponse | null;
  loading: boolean;
  error: any;
  status: string;
}

const INITIAL_STATE = {
  documentData: null,
  loading: false,
  error: null,
  status: "idle"
} as ISearchDocumentSlice;

export const _searchDocument = createAsyncThunk(
  "myGovernance/searchDocument",
  myGovernanceApi.searchDocument
);

export const searchDocumentSlice = createSlice({
  name: "searchDocument",
  initialState: INITIAL_STATE,
  reducers: {
  },
  extraReducers: (builder) => {
    builder
      .addCase(_searchDocument.pending, (state) => {
        state.loading = true;
        state.status = API_RESPONSE_STATUS.LOADING;
      })
      .addCase(_searchDocument.fulfilled, (state, action) => {
        state.loading = false;
        state.error = null;
        state.documentData = action.payload.data;
        state.status = API_RESPONSE_STATUS.SUCCESS;
      })
      .addCase(_searchDocument.rejected, (state, action) => {
        state.loading = false;
        state.documentData = null;
        state.error = action.payload;
        state.status = API_RESPONSE_STATUS.FAILED;
      })
  },
});