import { createSlice } from "@reduxjs/toolkit";
import { AbstractDataModel } from "../../../api/hep/hep-screen-model";
import { IHepContext } from "./cachedRetrieveHepContextSlice";

const INITIAL_STATE = {
    data: {
        activityId: "",
        requestId: "",
        stage: 0,
        status: "",
        data: [] as AbstractDataModel
    } as IHepContext
}


export const hepContextSharedSlice = createSlice({
    name: "hepContextShared",
    initialState: INITIAL_STATE,
    reducers: {
        setGlobalContext: (state, action) => {
            state.data = action.payload;
        },
    },
});

// Action creators are generated for each case reducer function
export const { setGlobalContext } = hepContextSharedSlice.actions;

export default hepContextSharedSlice.reducer;
