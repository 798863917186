import httpClient from "../../../common/http/httpClient";
import { IDefaultResponse } from "../../common/common.model";
import { IRecord, ITemplate } from "../model/craft-search.model";
import { IRecordList } from "../model/record-list.model";
import { DeleteRecordRequest, IAssignCraftRecordRequest, createRecordRequest } from "../model/record.model";
import {
  searchTemplateIdRequest,
  searchTemplateNameRequest,
} from "../model/searchTemplate.type";
import { ICraftService } from "./craft.model";

const craftService = (): ICraftService => {
  return {
    searchTemplateById: (
      req: searchTemplateIdRequest
    ): HttpPromise<any> => {
      return httpClient
        .get<any>(`/local-json/searchTempleteById.json`)
        .then((res) => res);
    },
    searchTemplateByName: (
      req: searchTemplateNameRequest
    ): HttpPromise<any> => {
      return httpClient
        .get<any>(`/local-json/searchTempleteByName.json`)
        .then((res) => res);
    },
    searchTemplate: (
      searchString: string
    ): HttpPromise<ITemplate[]> => {
      return httpClient
        .get<ITemplate[]>(`${process.env.REACT_APP_API_BASE_URL}/api/craft-management/template/search?search-string=${searchString}`)
        .then((res) => res);
    },
    searchRecord: (
      searchString: string
    ): HttpPromise<IRecord[]> => {
      return httpClient
        .get<IRecord[]>(`${process.env.REACT_APP_API_BASE_URL}/api/craft-management/record/search?search-string=${searchString}`)
        .then((res) => res);
    },
    deleteRecord: (
      request: DeleteRecordRequest
    ): HttpPromise<any> => {
      return httpClient
        .delete<any>(`${process.env.REACT_APP_API_BASE_URL}/api/craft-management/record/delete`, {data:request})
        .then((res) => res);
    },
    createRecord: (
      request: createRecordRequest
    ): HttpPromise<any> => {
      return httpClient
        .post<any>(`${process.env.REACT_APP_API_BASE_URL}/api/craft-management/record/create`, {data:request})
        .then((res) => res);
    },
    listRecords: (
      workOrderId: string
    ): HttpPromise<IRecordList[]> => {
      return httpClient
        .get<IRecordList[]>(`${process.env.REACT_APP_API_BASE_URL}/api/craft-management/record/list?work-order-id=${workOrderId}`)
        .then((res) => res);
    },
    assignCraftRecord: (
      request: IAssignCraftRecordRequest
    ): HttpPromise<IDefaultResponse> => {
      return httpClient
        .post<IDefaultResponse>(`${process.env.REACT_APP_API_BASE_URL}/api/scheduler/create-update-record`, {data:request})
        .then((res) => res);
    },
  };
};

export default craftService();
