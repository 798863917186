import {
  ITeamManagementService,
} from "./team-management.model";

import httpClient from "../../common/http/httpClient";

const TeamManagementService = (): ITeamManagementService => {
  return {
    createUpdateTeamMembers: (
      resourseList: any
    ): HttpPromise<any> => {
      return httpClient
        .post<any[]>(
          `${process.env.REACT_APP_API_BASE_URL}/api/team-management/create-update-team-members`,
          { data: resourseList }
        )
        .then((res) => res);
    }
  };
};

export default TeamManagementService();
