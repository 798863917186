import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { operationAPI } from "../../../api/service";

export const INITIAL_STATE = {
    listCertificate: null,
    listEquipment: null,
    listPermit: null,
    loading: false,
    error: null,
    status: "idle",
} as any;

export const _getListCertificate = createAsyncThunk(
    "api/maintenance-order/get-maintenance-order-tooltip/certificate",
    operationAPI.getMaintenanceOrderTooltip
);
export const _getListEquipment = createAsyncThunk(
    "api/maintenance-order/get-maintenance-order-tooltip/equipment",
    operationAPI.getMaintenanceOrderTooltip
);
export const _getListPermit = createAsyncThunk(
    "api/maintenance-order/get-maintenance-order-tooltip/permit",
    operationAPI.getMaintenanceOrderTooltip
);

export const maintenanceOrderTooltipSlice = createSlice({
    name: "maintenanceOrderTooltipSlice",
    initialState: INITIAL_STATE,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(_getListCertificate.pending, (state) => {
                state.loading = true;
                state.status = "loading";
            })
            .addCase(_getListCertificate.fulfilled, (state, action) => {
                state.loading = false;
                state.error = null;
                state.listCertificate = action.payload.data;
                state.status = "succeeded";
            })
            .addCase(_getListCertificate.rejected, (state, action) => {
                state.loading = false;
                state.listCertificate = null;
                state.error = action.payload;
                state.status = "failed";
            })
            .addCase(_getListEquipment.pending, (state) => {
                state.loading = true;
                state.status = "loading";
            })
            .addCase(_getListEquipment.fulfilled, (state, action) => {
                state.loading = false;
                state.error = null;
                state.listEquipment = action.payload.data;
                state.status = "succeeded";
            })
            .addCase(_getListEquipment.rejected, (state, action) => {
                state.loading = false;
                state.listEquipment = null;
                state.error = action.payload;
                state.status = "failed";
            })
            .addCase(_getListPermit.pending, (state) => {
                state.loading = true;
                state.status = "loading";
            })
            .addCase(_getListPermit.fulfilled, (state, action) => {
                state.loading = false;
                state.error = null;
                state.listPermit = action.payload.data;
                state.status = "succeeded";
            })
            .addCase(_getListPermit.rejected, (state, action) => {
                state.loading = false;
                state.listPermit = null;
                state.error = action.payload;
                state.status = "failed";
            });
    },
});