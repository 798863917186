import { IDeleteUserRequest, ISaveUserRequest, ISearchUserManagementResponse, ISearchUserManagementTableRequest, IUserManagementService, IUserRole } from "./user-management.model";
import httpClient from "../../common/http/httpClient";

const UserManagementService = (): IUserManagementService => {
  return {
    searchUserManagementTable: (reqObj: ISearchUserManagementTableRequest): HttpPromise<ISearchUserManagementResponse> => {
      return httpClient
        .post<ISearchUserManagementResponse>(`${process.env.REACT_APP_API_BASE_URL}/api/user-management/search`, { data: reqObj })
        .then((res) => res);
    },
    saveUser: (reqObj: ISaveUserRequest): HttpPromise<any> => {
      return httpClient
        .post<any>(`${process.env.REACT_APP_API_BASE_URL}/api/user-management/save-user-profile`, { data: reqObj })
        .then((res) => res);
    },
    deleteUser: (reqObj: IDeleteUserRequest): HttpPromise<any> => {
      return httpClient
        .delete<any>(`${process.env.REACT_APP_API_BASE_URL}/api/user-management/delete-user`, { data: reqObj })
        .then((res) => res);
    },
    getRoles: (userId: any): HttpPromise<any> => {
      return httpClient
        .post<any>(`${process.env.REACT_APP_API_BASE_URL}/api/role-management/get-roles-list`,{data: userId})
        .then((res) => res);
    },
    getRolesForRequestAccess: (): HttpPromise<IUserRole[]> => {
      return httpClient
        .get<IUserRole[]>(`${process.env.REACT_APP_API_BASE_URL}/api/role-management/get-roles-for-request-access`)
        .then((res) => res);
    },
    getApplicationUsers: (role: string): HttpPromise<any> => {
      return httpClient
        .get<any>(`${process.env.REACT_APP_API_BASE_URL}/api/user-management/get-application-users?role=${role}`)
        .then((res) => res);
    }

  };
};

export default UserManagementService();
