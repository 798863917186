import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { INonWorkHour } from "../../../../api/team-resource/nonwork-hour/team-nonwork-hour.model";
import { nonWorkHourAPI } from "../../../../api/service";


export interface INonWorkHourListSlice {
  nonWorkList: INonWorkHour[] | null;
  loading: boolean;
  error: any;
  status: string;
}

const INITIAL_STATE = {
  nonWorkList: null,
  loading: false,
  error: null,
  status: "idle",
} as INonWorkHourListSlice;

export const getNonWorkHourList = createAsyncThunk(
  "team/getNonWorkHourList",
  nonWorkHourAPI.getNonWorkHourList
);

const nonWorkHourListSlice = createSlice({
  name: "nonWorkList",
  initialState: INITIAL_STATE,
  reducers: {
  },
  extraReducers: (builder) => {
    builder
      .addCase(getNonWorkHourList.pending, (state) => {
        state.loading = true;
        state.status = "loading";
      })
      .addCase(getNonWorkHourList.fulfilled, (state, action) => {
        state.loading = false;
        state.error = null;
        state.nonWorkList = action.payload.data;
        state.status = "succeeded";
      })
      .addCase(getNonWorkHourList.rejected, (state, action) => {
        state.loading = false;
        state.nonWorkList = null;
        state.error = action.payload;
        state.status = "failed";
      });
  },
});

export default nonWorkHourListSlice.reducer;

export const {  } = nonWorkHourListSlice.actions;
