import React, { lazy, Suspense } from "react";
import { createBrowserRouter, Navigate, Route } from "react-router-dom";
import Page from "../components/pages/page/Page";
import paths from "../routes/paths";
import _ from "lodash";
import { SplashScreen } from "../components/pages/splash-screen/SplashScreen";
import { ROLE } from "../constants/auth.constant";
import { Spin } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import { LoginStatusConst, useCurrentUser } from "../hooks/auth/useCurrenttUser";

const Planning = lazy(() => import("../components/pages/planning/Planning"));
const AccessDenied = lazy(
  () => import("../components/pages/access-denied/AccessDenied")
);
const AdminPortal = lazy(
  () => import("../components/pages/admin-portal/AdminPortal")
);
const Scheduling = lazy(
  () => import("../components/pages/scheduling/Scheduling")
);
const LandingScreen = lazy(
  () => import("../components/pages/landing-screen/LandingScreen")
);
const HepLandingScreen = lazy(
  () => import("../components/pages/hep/HepLandingPage")
);

const PageNotFound = lazy(
  () => import("../components/pages/not-found/PageNotFound")
);

/* Technician Board Lazy load components */
const TechnicianBoard = lazy(
  () => import("../components/pages/technician-board/TechnicianBoard")
);
const TechnicianBoardDetails = lazy(() =>
  import("../components/pages/technician-board/TechnicianBoardDetails").then(
    ({ TechnicianBoardDetails }) => ({ default: TechnicianBoardDetails })
  )
);
const TechnicianBoardUpdate = lazy(() =>
  import("../components/pages/technician-board/TechnicianBoardDetails").then(
    ({ TechnicianBoardUpdate }) => ({ default: TechnicianBoardUpdate })
  )
);
const HepRequestScreen = lazy(
  () => import("../components/pages/hep/HepRequestPage")
);

interface Routes {
  path: string;
  element?: React.ReactNode;
  children?: Routes[];
  roles?: string[];
}

const getRouteElement = (Component: React.ElementType): React.ReactNode => (
  <Suspense fallback={<SplashScreen />}>
    <Page>
      <Component />
    </Page>
  </Suspense>
);

const getRouteElementWithoutHeader = (
  Component: React.ElementType
): React.ReactNode => (
  <Suspense fallback={<SplashScreen />}>
    <Component />
  </Suspense>
);

const ProtectedRoute = ({
  children,
  roles = []
}: {
  children: React.ReactNode;
  roles: string[]
  hep?: boolean
}) => {
  const { currentUser } = useCurrentUser();

  if (!currentUser) {
    return (
      <>
        <SplashScreen></SplashScreen>
        <Spin
          indicator={
            <LoadingOutlined style={{ fontSize: 72, color: "#00a19c" }} spin />
          }
          spinning={true}
          fullscreen
        />
      </>
    );
  }

  if (currentUser.loginStatus !== LoginStatusConst.Active || currentUser.roles.length == 0) {
    return <Navigate to={paths.ACCESS_DENIED}></Navigate>;
  }

  // This case is use for Switch role
  const storedSelectedRole = localStorage.getItem('WorkSelectedRole');
  if ((currentUser?.roles.length > 1 && !storedSelectedRole) || (storedSelectedRole && window.location.pathname === '/')) {
    localStorage.removeItem('WorkSelectedRole');
    localStorage.removeItem('WorkSelectedTeam');
    localStorage.removeItem('_selectedTeamInfo');

    // Clear the pathname when we are in landing screen
    if (window.location.pathname !== '/') {
      window.location.pathname = '/'
    }
    return <>{getRouteElement(LandingScreen)}</>;
  }

  if (roles.length === 0 || currentUser?.roles.some(userRole => roles.includes(userRole.name))) {
    return <>{children}</>;
  }

  return <Navigate to={pathDefaultForRole[currentUser.roles[0]?.name]} />;
};

const pathDefaultForRole = {
  [ROLE.SUPER_INTENDENT]: paths.PLANNING,
  [ROLE.SCHEDULER]: paths.PLANNING,
  [ROLE.ADMIN]: paths.ADMIN_PORTAL,
  [ROLE.DEVELOPER]: paths.ADMIN_PORTAL,
  [ROLE.TECHNICIAN]: paths.TECHNICIAN_BOARD,
  [ROLE.PREPARER]: paths.HEP_HOME,
};
const routes: Routes[] = [
  {
    path: paths.PLANNING,
    element: (
      <ProtectedRoute roles={[ROLE.SCHEDULER]}>
        {getRouteElement(Planning)}
      </ProtectedRoute>
    ),
  },
  {
    path: paths.SCHEDULING,
    element: (
      <ProtectedRoute roles={[ROLE.SCHEDULER]}>
        {getRouteElement(Scheduling)}
      </ProtectedRoute>
    ),
  },
  {
    path: paths.LANDING_SCREEN,
    element: (
      <ProtectedRoute roles={[]}>
        {getRouteElement(LandingScreen)}
      </ProtectedRoute>
    ),
  },
  { path: paths.NOT_FOUND, element: <PageNotFound /> },
  {
    path: paths.ACCESS_DENIED,
    element: <AccessDenied />,
  },
  {
    path: paths.ADMIN_PORTAL,
    children: [
      {
        path: "",
        element: (
          <ProtectedRoute roles={[ROLE.ADMIN, ROLE.DEVELOPER]}>
            {getRouteElement(AdminPortal)}
          </ProtectedRoute>
        ),
      },
    ],
  },
  {
    path: paths.TECHNICIAN_BOARD,
    element: (
      <ProtectedRoute roles={[ROLE.TECHNICIAN]}>
        {getRouteElement(TechnicianBoard)}
      </ProtectedRoute>
    ),
  },
  {
    path: `${paths.TECHNICIAN_BOARD}/:id`,
    element: (
      <ProtectedRoute roles={[ROLE.TECHNICIAN]}>
        {getRouteElement(TechnicianBoardDetails)}
      </ProtectedRoute>
    ),
  },
  {
    path: `${paths.HEP_HOME}`,
    element: (
      <ProtectedRoute roles={[ROLE.PREPARER]} hep={true}>
        {getRouteElement(HepLandingScreen)}
        </ProtectedRoute>
    ),
  },
  {
    path: `${paths.HEP_HOME}/:id`,
    element: (
      <ProtectedRoute roles={[ROLE.PREPARER]} hep={true}>
        {getRouteElement(HepRequestScreen)}
        </ProtectedRoute>
    ),
  },
  {
    path: `${paths.TECHNICIAN_BOARD}/:id/update`,
    element: (
      <ProtectedRoute roles={[ROLE.TECHNICIAN]}>
        {getRouteElement(TechnicianBoardUpdate)}
      </ProtectedRoute>
    ),
  },
];

console.log("routes==== ", routes)

export default createBrowserRouter(routes);
