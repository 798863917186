import { combineReducers } from "@reduxjs/toolkit";
import createNewRequestHEPSlice from "./createNewRequestHEPSlice";
import requestsDataSlice from "./getRequestsSlice";
import sharedHepContextSliceReducer from "./sharedHepContextSlice";
import cachedRetrieveHepContextReducer from "./cachedRetrieveHepContextSlice";

export const hepRooteReducer = combineReducers({
    createNewRequestHEP: createNewRequestHEPSlice.reducer,
    getRequests: requestsDataSlice.reducer,
    globalContextData: sharedHepContextSliceReducer,
    cachedRetrieveContext: cachedRetrieveHepContextReducer,
});