import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { searchCraftAPI } from "../../../api/service";

const INITIAL_STATE = {
  response: null,
  loading: false,
  error: null,
  status: "idle",
} as any;

export const _searchTemplate = createAsyncThunk(
  "api/craftmanagement/searchTemplateSlice",
  searchCraftAPI.searchTemplate
);

export const searchTemplateSlice = createSlice({
  name: "searchTemplateSlice",
  initialState: INITIAL_STATE,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(_searchTemplate.pending, (state) => {
        state.loading = true;
        state.status = "loading";
      })
      .addCase(_searchTemplate.fulfilled, (state, action) => {
        state.loading = false;
        state.error = null;
        state.response = action.payload.data;
        state.status = "succeeded";
      })
      .addCase(_searchTemplate.rejected, (state, action) => {
        state.loading = false;
        state.response = null;
        state.error = action.payload;
        state.status = "failed";
      });
  },
});
