import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { commonAPI } from "../../../api/service";
import { IOpu } from "../../../api/common/common.model";

export interface ICommonSlice {
  opuList: IOpu[];
  opuLoading: boolean;
  error: any;
  status: string;
}

const INITIAL_STATE = {
  opuList: [],
  opuLoading: false,
  error: null,
  status: "idle",
} as ICommonSlice;

export const getOpuList = createAsyncThunk(
  "common/getOpuList",
  commonAPI.getOpuList
);

const commonSlice = createSlice({
  name: "common",
  initialState: INITIAL_STATE,
  reducers: {
  },
  extraReducers: (builder) => {
    builder
      .addCase(getOpuList.pending, (state) => {
        state.opuLoading = true;
        state.status = "loading";
      })
      .addCase(getOpuList.fulfilled, (state, action) => {
        state.opuLoading = false;
        state.error = null;
        state.opuList = action.payload.data.searchResults;
        state.status = "succeeded";
      })
      .addCase(getOpuList.rejected, (state, action) => {
        state.opuLoading = false;
        state.opuList = [];
        state.error = action.payload;
        state.status = "failed";
      })
  },
});

export default commonSlice.reducer;

// export const {  } = commonSlice.actions;
