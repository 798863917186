import { createSlice } from "@reduxjs/toolkit";
import { ICraftRecord } from "../../../api/craft/model/record.model";
import { DocumentDataDetails } from "../../../api/maintenance-order/my-governance.model";

const initialState = {
  documentRecord: {} as DocumentDataDetails}

export const documentRecordAddSlice = createSlice({
  name: "documentRecordAdd",
  initialState,
  reducers: {
    selectDocumentRecord: (state, action) => {
      state.documentRecord = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const { selectDocumentRecord } = documentRecordAddSlice.actions;

export default documentRecordAddSlice.reducer;
