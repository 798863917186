import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { ITaskDetailSlice } from "../../../api/technician-board/technician-board.model";
import { technicianBoardAPI } from "../../../api/service";

export const INITIAL_STATE = {
  response: null,
  loading: false,
  error: null,
  status: "idle",
} as ITaskDetailSlice;

export const _getTaskDetails = createAsyncThunk(
    "api/technician/taskDetails",
    technicianBoardAPI.getTaskDetail
);

export const _refreshTask = createAsyncThunk(
    "api/technician/refreshTask",
    technicianBoardAPI.refreshTaskDetail
);

export const getTaskDetailsSlice = createSlice({
    name: "taskDetails",
    initialState: INITIAL_STATE,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(_getTaskDetails.pending, (state) => {
                state.loading = true;
                state.status = "loading";
            })
            .addCase(_getTaskDetails.fulfilled, (state, action) => {
                state.loading = false;
                state.error = null;
                state.response = action.payload.data;
                state.status = "succeeded";
            })
            .addCase(_getTaskDetails.rejected, (state, action) => {
                state.loading = false;
                state.response = null;
                state.error = action.payload;
                state.status = "failed";
            });
    },
});