import { useLayoutEffect } from "react";
import { removeBodyCssClass, setBodyCssClass } from "../../../utils/common.utils";
import "./SplashScreen.scss"

export const SplashScreen = () => {

  useLayoutEffect(() => {
    setBodyCssClass("landing-screen-bg");

    return () => {
      removeBodyCssClass("landing-screen-bg");
    };
  }, []);

  return <></>
};


