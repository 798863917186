import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { teamDefaultHourAPI } from "../../../api/service";
import { ITeamHour } from "../../../api/team-default-hour/team-default-hour.model";


export interface ITeamHourListSlice {
  teamHourList: ITeamHour[] | null;
  loading: boolean;
  error: any;
  status: string;
}

const INITIAL_STATE = {
  teamHourList: null,
  loading: false,
  error: null,
  status: "idle",
} as ITeamHourListSlice;

export const getTeamsHourList = createAsyncThunk(
  "team/getTeamHourList",
  teamDefaultHourAPI.getTeamHourList
);

const teamHourListSlice = createSlice({
  name: "teamHourList",
  initialState: INITIAL_STATE,
  reducers: {
  },
  extraReducers: (builder) => {
    builder
      .addCase(getTeamsHourList.pending, (state) => {
        state.loading = true;
        state.status = "loading";
      })
      .addCase(getTeamsHourList.fulfilled, (state, action) => {
        state.loading = false;
        state.error = null;
        state.teamHourList = action.payload.data;
        state.status = "succeeded";
      })
      .addCase(getTeamsHourList.rejected, (state, action) => {
        state.loading = false;
        state.teamHourList = null;
        state.error = action.payload;
        state.status = "failed";
      });
  },
});

export default teamHourListSlice.reducer;

export const {  } = teamHourListSlice.actions;
