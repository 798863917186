export const getUserName = () => {
  return sessionStorage.getItem("userName");
};

export const getUserEmail = () => {
  return sessionStorage.getItem("userEmail");
};

export const getUserId = () => {
  return sessionStorage.getItem("userId");
}

export const isUserLogin = (): boolean => {
  const _loginState = sessionStorage.getItem("loginState");
  let loginFlag = _loginState ? true : false;
  let isLogin = false;
  if (loginFlag === true) {
    isLogin = true;
  } else {
    isLogin = false;
  }
  return isLogin;
};

export const getAccountEmail = () => {
  return getUserAccount().idTokenClaims.email;
};

const getUserAccount = () => {
  let _account = sessionStorage.getItem("userAccount") as any;
  let userAccount = JSON.parse(_account) as IAccount;
  return userAccount;
};

interface IAccount {
  idTokenClaims: { email: string; name: string };
}
