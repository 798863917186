export const DEFAULT_PAGE_SIZE = 10;

export const enum API_RESPONSE_STATUS {
  SUCCESS = "succeeded",
  FAILED = "failed",
  LOADING = "loading",
}

export const enum MESSAGE_TYPE {
  SUCCESS = "success",
  ERROR = "error",
  WARNING = "warning",
  INFO = "info",
}

export const PLANNED_HOUR_FORMAT_REGEX =  /^-?\d+(\.5)?$|^-?\.5$|^-?\d+\.$/;
