import { createSlice } from "@reduxjs/toolkit";
import {BaseIntegrationRecordItem} from "../integration-record-action/integrationRecord.model"


const initialState = {
  waAction: {
    recordTab: 0, mode: '', 
    apiResponse: { isError: false, message: '', responseData: null }, 
    refreshRequired: true, 
    recordData: {} as BaseIntegrationRecordItem,
    isNotify: false
  }
};

export const workAssignmentActionSlice = createSlice({
  name: "workAssigmentAction",
  initialState,
  reducers: {
    setWAAction: (state, action) => {
      state.waAction = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const { setWAAction } = workAssignmentActionSlice.actions;

export default workAssignmentActionSlice.reducer;
