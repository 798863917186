import {
  ReactNode,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";
import { authAPI } from "../../api/service";
import { isUserLogin } from "../../utils/user.utils";
import { login } from "../../utils/authentication";
import { AxiosError } from "axios";
import { IRole } from "../../components/layout/Admin/AdminPortal/UserManagement/userManagement.model";
import { ILoginResponse } from "../../api/auth/auth.model";
import { ROLE } from "../../constants/auth.constant";

export const LoginStatusConst = {
  Active: 'Active',
  PedingRequest: 'PedingRequest',
  Disabled: 'Disabled',
  NotExisted: 'NotExisted'
}

export interface IUser {
  userId: string;
  fullName: string;
  email: string;
  roles: IRole[];
  selectedRole?: IRole
  accessHistoryId?: string;
  loginTimeStamp?: Date;
  loginStatus: string;
  isDeveloper: boolean;
}

export interface ICurrentUserContext {
  currentUser: IUser | null;
  _isInRoles: (roles: string[]) => boolean;
  _setSelectedRole: (role: string) => void;
}

export const CurrentUserContext = createContext<ICurrentUserContext>(
  {} as ICurrentUserContext
);

export const useCurrentUser = () => {
  return useContext(CurrentUserContext);
};

export const CurrentUserContextProvider = ({
  children,
}: {
  children: ReactNode;
}) => {
  const [currentUser, setCurrentUser] = useState<IUser | null>(null);

  const isInRoles = useCallback((roles: string[]): boolean => {
    if (currentUser?.loginStatus !== LoginStatusConst.Active) {
      return false;
    }

    if (roles.length === 0) {
      return true; // Allow access if no roles are provided
    }

    if (!currentUser.selectedRole?.name) {
      return false;
    }

    // Check if any of the roles in currentUser.roles match any of the roles provided
    return roles.includes(currentUser.selectedRole.name)
  }, [currentUser?.roles, currentUser?.selectedRole?.name]);

  const setSelectedRole = useCallback((selectedRole: string) => {

    const roleSelected = currentUser?.roles?.find(role => role.name === selectedRole);

    if (!roleSelected || !currentUser) {
      return;
    }

    setCurrentUser({
      ...currentUser,
      selectedRole: {
        id: roleSelected.id,
        name: roleSelected.name
      },
      isDeveloper: roleSelected?.name === ROLE.DEVELOPER
    });
  }, [currentUser?.roles])

  useEffect(() => {
    async function fetchUser(userId: string) {
      try {
        const { data: user }: { data: ILoginResponse } = await authAPI.login({
          userId: userId,
        });

        const selectedRoleCached = localStorage.getItem("WorkSelectedRole")
        let selectedRole = user.roles?.find(x => x.name === selectedRoleCached);
        if (!selectedRole && user.roles?.length > 0) {
          selectedRole = user.roles[0];
        }
        setCurrentUser({
          userId: user.userId,
          email: user.email,
          fullName: user.fullName,
          roles: user.roles,
          selectedRole: selectedRole,
          loginStatus: user.loginStatus,
          isDeveloper: selectedRole?.name === ROLE.DEVELOPER
        });
      } catch (error) {
        const axiosError = error as AxiosError;
        if (axiosError.response?.status === 403) {
          setCurrentUser({
            userId: "",
            email: "",
            fullName: "",
            roles: [],
            loginStatus: LoginStatusConst.NotExisted,
            isDeveloper: false,
          });
        }
        console.error(error);
      }
    }

    if (!isUserLogin()) {
      login();
      return;
    }

    let userId = sessionStorage.getItem("accountId");
    if (!userId) {
      sessionStorage.clear();
      login();
      return;
    }

    fetchUser(userId);
  }, []);

  return (
    <CurrentUserContext.Provider value={{ currentUser, _isInRoles: isInRoles, _setSelectedRole: setSelectedRole }}>
      {children}
    </CurrentUserContext.Provider>
  );
};
