import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  isCreated: false,
  workInformation: null,
  templateInformation: null
};

export const createCraftSlice = createSlice({
  name: "createCraft",
  initialState,
  reducers: {
    created: (state, action) => {
      state.isCreated = action.payload;
    },
    setWorkInformation: (state, action) => {
      state.workInformation = action.payload;
    },
    setTemplateInformation: (state, action) => {
      state.templateInformation = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const { created, setWorkInformation, setTemplateInformation } = createCraftSlice.actions;

export default createCraftSlice.reducer;
