import axios, {
  AxiosInstance,
  AxiosResponse,
  Method,
  InternalAxiosRequestConfig,
  AxiosPromise,
} from "axios";
import { login } from "../../utils/authentication";

export interface Request {
  headers?: Record<string, string>;
  data?: any;
  params?: any;
}

export class HttpReqClients {
  private httpClient: AxiosInstance;

  constructor() {
    this.httpClient = axios.create({
      baseURL: "http://localhost:3000/",
      timeout: 60000,
    });

    this.httpClient.interceptors.request.use(
      (config) => {
        config.headers["Authorization"] = `Bearer ${sessionStorage.getItem(
          "id_token"
        )}`;
        return config;
      },
      (error) => {
        return Promise.reject(error);
      }
    );
    this.httpClient.interceptors.response.use(
      (response) => response,
      (error) => {
        const status = error.response ? error.response.status : null;
        if (status === 401) {
          // Handle unauthorized acces
          localStorage.clear();
          sessionStorage.clear();
          login();
        } else if (status === 404) {
          // Handle not found errors
        } else {
          // Handle other errors
        }

        return Promise.reject(error);
      }
    );
  }

  private handleRequestUse(config: InternalAxiosRequestConfig) {
    // handle request interceptor logic here
    return config;
  }

  private handleResponseUse(config: AxiosResponse) {
    // handle response interceptor logic here
    return config;
  }
  private async handleRequest(
    url: string,
    method: Method,
    config: Request = {},
    responseType: "json" | "arraybuffer" | "blob" | "document" | "text" = "json"
  ): Promise<AxiosResponse<any>> {
    const { headers, data, params } = config;
    const response = await this.httpClient.request({
      url,
      method,
      data,
      params,
      headers,
      responseType
    });
    return response;
  }

  public get<T>(url: string, config: Request = {}): AxiosPromise<T> {
    return this.handleRequest(url, "get", config);
  }

  public post<T>(url: string, config: Request = {}, responseType: "json" | "arraybuffer" | "blob" | "document" | "text" = "json"): AxiosPromise<T> {
    return this.handleRequest(url, "post", config, responseType);
  }

  public put<T>(url: string, config: Request = {}): AxiosPromise<T> {
    return this.handleRequest(url, "put", config);
  }

  public delete<T>(url: string, config: Request = {}): AxiosPromise<T> {
    return this.handleRequest(url, "delete", config);
  }

  public patch<T>(url: string, config: Request = {}): AxiosPromise<T> {
    return this.handleRequest(url, "patch", config);
  }
}

const HttpClient = new HttpReqClients();
export default HttpClient;
