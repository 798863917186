import {
  IOrderService,
  IGetAllOrderResponse, IOrder, IGetMaintenanceOrderRequest,
  IGetAllAvailableHoursResponse, IAvailableHours, IAvailableHoursRequest,
  IOrderCreateUpdateRequest, IOrderCreateUpdateResponse, IListOrderCreateUpdateRequest, IOrderUpdateRequest,
  IGetMaintenanceOrderSummaryRequest,
  IGetMaintenanceOrderSummaryResponse
} from "./order.model";

import httpClient from "../../common/http/httpClient";
import { AxiosResponse } from "axios";

const OrderService = (): IOrderService => {
  return {
    getAllOrders: (): HttpPromise<IGetAllOrderResponse> => {
      return httpClient
        .get<IOrder[]>("/local-json/orderList.json")
        .then((res: AxiosResponse<IOrder[], any>) => res);
    },
    getAllAvailableHours: (request: IAvailableHoursRequest): HttpPromise<IGetAllAvailableHoursResponse> => {
      return httpClient
        .post<IAvailableHours[]>(`${process.env.REACT_APP_API_BASE_URL}/api/maintenance-order/get-maintenance-order-weekly-availability`, { data: request })
        .then((res: AxiosResponse<IAvailableHours[], any>) => res);
    },
    getFilteredOrders: (request: IGetMaintenanceOrderRequest): HttpPromise<IGetAllOrderResponse> => {
      return httpClient
        .post<IGetAllOrderResponse>(`${process.env.REACT_APP_API_BASE_URL}/api/maintenance-order/search`, { data: request })
        .then((res: AxiosResponse<IGetAllOrderResponse, any>) => res);
    },
    createOrderSchedule: (request: IOrderCreateUpdateRequest): HttpPromise<IOrderCreateUpdateResponse> => {
      return httpClient
        .post<IOrderCreateUpdateResponse>(`${process.env.REACT_APP_API_BASE_URL}/api/maintenance-order/create`, { data: request })
        .then((res: AxiosResponse<IOrderCreateUpdateResponse, any>) => res);
    },
    updateOrderSchedule: (request: IListOrderCreateUpdateRequest): HttpPromise<IOrderCreateUpdateResponse> => {
      return httpClient
        .post<IOrderCreateUpdateResponse>(`${process.env.REACT_APP_API_BASE_URL}/api/maintenance-order/update`, { data: request })
        .then((res: AxiosResponse<IOrderCreateUpdateResponse, any>) => res);
    },
    getMaintenanceOrderSummary: (request: IGetMaintenanceOrderSummaryRequest): HttpPromise<IGetMaintenanceOrderSummaryResponse> => {
      return httpClient
        .post<IGetMaintenanceOrderSummaryResponse>(`${process.env.REACT_APP_API_BASE_URL}/api/maintenance-order/get-maintenance-order-summary`, { data: request })
        .then((res: AxiosResponse<IGetMaintenanceOrderSummaryResponse, any>) => res);
    }
  };
};

export default OrderService();
