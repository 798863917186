import { createAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { planningChartAPI } from "../../../api/service";
import { ISchedulerChartData } from "../../../api/planning-charts/planning-chart.model";
import { DocumentDataResponse } from "../../../api/maintenance-order/my-governance.model";
import myGovernanceApi from "../../../api/maintenance-order/my-governance.api";
import { API_RESPONSE_STATUS } from "../../../constants/common.constant";

export interface IGetAssignedDocument {
  documentData: DocumentDataResponse | null;
  loading: boolean;
  error: any;
  status: string;
}

const INITIAL_STATE = {
  documentData: null,
  loading: false,
  error: null,
  status: "idle"
} as IGetAssignedDocument;

export const _getAssignedDocument = createAsyncThunk(
  "myGovernance/getAssinedDocument",
  myGovernanceApi.getAssignedDocument      
);

export const _deleteDocumentSlice = createAction<string>('/myGovernance/getAssinedDocument/deleteDocument')
export const _addDocument = createAction<any>('/myGovernance/getAssinedDocument/addDocument')

export const getAssignedDocumentSlice = createSlice({
  name: "getAssinedDocument",
  initialState: INITIAL_STATE,
  reducers: {
  },
  extraReducers: (builder) => {
    builder
      .addCase(_getAssignedDocument.pending, (state) => {
        state.loading = true;
        state.status = API_RESPONSE_STATUS.LOADING;
      })
      .addCase(_getAssignedDocument.fulfilled, (state, action) => {
        state.loading = false;
        state.error = null;
        state.documentData = action.payload.data;
        state.status = API_RESPONSE_STATUS.SUCCESS;
      })
      .addCase(_getAssignedDocument.rejected, (state, action) => {
        state.loading = false;
        state.documentData = null;
        state.error = action.payload;
        state.status = API_RESPONSE_STATUS.FAILED;
      })
      .addCase(_deleteDocumentSlice, (state, action) => {
        const list = state.documentData?.data.filter((item) => item.documentCode !== action.payload) || [];
        const updatedData = { data: list, total: list?.length }
        state.documentData = updatedData;
      })
      .addCase(_addDocument, (state, action) => {
        const list = state.documentData?.data || []
        list.push(action.payload)
        const updatedData = { data: list, total: list?.length }
        state.documentData = updatedData;
      })
  },
});