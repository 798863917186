import { createSlice } from "@reduxjs/toolkit";


const initialState = {
  myGovernanceAction: {
    mode: '', message: ''
  }
};

export const documentActionSlice = createSlice({
  name: "documentAction",
  initialState,
  reducers: {
    setDocumentAction: (state, action) => {
      state.myGovernanceAction = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const { setDocumentAction } = documentActionSlice.actions;

export default documentActionSlice.reducer;
