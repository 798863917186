import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { schedulingCalendarAPI } from "../../../api/service";

export interface IGetAvaiblableHourByDateAndTeamId {
  hourListResponse: any;
  loading: boolean;
  error: any;
  status: string;
}

const INITIAL_STATE = {
  hourListResponse: null,
  loading: false,
  error: null,
  status: "idle",
} as IGetAvaiblableHourByDateAndTeamId;

export const getAvailableHours = createAsyncThunk(
  "api/maintenanceorder/getmaintenanceorderweeklyavailability",
  schedulingCalendarAPI.getAvailableHourByDateAndTeamId
);

export const GetAvailableHoursList = createSlice({
  name: "getAvailableHourByDateAndTeamId",
  initialState: INITIAL_STATE,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getAvailableHours.pending, (state) => {
        state.loading = true;
        state.status = "loading";
      })
      .addCase(getAvailableHours.fulfilled, (state, action) => {
        state.loading = false;
        state.error = null;
        state.hourListResponse = action.payload.data;
        state.status = "succeeded";
      })
      .addCase(getAvailableHours.rejected, (state, action) => {
        state.loading = false;
        state.hourListResponse = null;
        state.error = action.payload;
        state.status = "failed";
      });
  },
});

