import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  teamId: "",
  teamName: "",
  scheduleStartDate: "",
  scheduleEndDate: "",
  workOrderId: "",
};

// Strore Global Work Order Values
export const woGlobalValueSlice = createSlice({
  name: "woGlobalValue",
  initialState,
  reducers: {
    setWorkOrderValues: (state, action) => {
      state.teamId = action.payload.teamId
        ? action.payload.teamId
        : state.teamId;
      state.teamName = action.payload.teamName
        ? action.payload.teamName
        : state.teamName;
      state.scheduleStartDate = action.payload.scheduleStartDate
        ? action.payload.scheduleStartDate
        : state.scheduleStartDate;
      state.scheduleEndDate = action.payload.scheduleEndDate
        ? action.payload.scheduleEndDate
        : state.scheduleEndDate;
      state.workOrderId = action.payload.workOrderId || state.workOrderId;
    },
  },
});

// Action creators are generated for each case reducer function
export const { setWorkOrderValues } = woGlobalValueSlice.actions;

export default woGlobalValueSlice.reducer;
