import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { planningChartAPI } from "../../../api/service";
import {  ISchedulerChartData } from "../../../api/planning-charts/planning-chart.model";

export interface IPlanningChartSlice {
  planningCharts: ISchedulerChartData[] | null;
  loading: boolean;
  error: any;
  status: string;
}

const INITIAL_STATE = {
  planningCharts: null,
  loading: false,
  error: null,
  status: "idle"
} as IPlanningChartSlice;

export const getSchedulerChartScrolling = createAsyncThunk(
  "planningChart/getSchedulerChartScrolling",
  planningChartAPI.getSchedulerChartScrolling
);

export const planningChartSlice = createSlice({
  name: "planningChart",
  initialState: INITIAL_STATE,
  reducers: {
  },
  extraReducers: (builder) => {
    builder
      .addCase(getSchedulerChartScrolling.pending, (state) => {
        state.loading = true;
        state.status = "loading";
      })
      .addCase(getSchedulerChartScrolling.fulfilled, (state, action) => {
        state.loading = false;
        state.error = null;
        state.planningCharts = action.payload.data;
        state.status = "succeeded";
      })
      .addCase(getSchedulerChartScrolling.rejected, (state, action) => {
        state.loading = false;
        state.planningCharts = null;
        state.error = action.payload;
        state.status = "failed";
      })
  },
});

export const {  } = planningChartSlice.actions;
