import { combineReducers } from "@reduxjs/toolkit";
import { searchTemplateByIdSlice } from "./searchTemplateByIdSlice";
import { searchTemplateByNameSlice } from "./searchTemplateByNameSlice";
import { searchTemplateSlice } from "./searchTemplateSlice";
import { searchRecordSlice } from "./searchRecordSlice";
import { deleteRecordSlice } from "./deleteRecordSlice";
import { createRecordSlice } from "./createRecordSlice";
import { listRecordSlice } from "./listRecordSlice";
import craftRecordAddReducer from "./craftRecordAddSlice";
import createCraftReducer from "./createCraftSlice";
import globalCraftReducer from './globalCraftSlice';
import { assignCraftRecordSlice }  from "./assignCraftRecordSlice";

export const craftRootReducer = combineReducers({
  searchTemplateById: searchTemplateByIdSlice.reducer,
  searchTemplateByName: searchTemplateByNameSlice.reducer,
  searchTemplate: searchTemplateSlice.reducer,
  searchRecord: searchRecordSlice.reducer,
  deleteRecord: deleteRecordSlice.reducer,
  createRecord: createRecordSlice.reducer,
  listRecord: listRecordSlice.reducer,
  addCraftRecord: craftRecordAddReducer,
  createCraft: createCraftReducer,
  globalCraft: globalCraftReducer,
  assignCraftRecord: assignCraftRecordSlice.reducer,
});