import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { ITeamExternal } from "../../../api/team-resource/team-external-list.model";
import { teamExternalListAPI } from "../../../api/service";


export interface ITeamExternalListSlice {
  teamExternalList: ITeamExternal[] | null;
  loading: boolean;
  error: any;
  status: string;
}

const INITIAL_STATE = {
  teamExternalList: null,
  loading: false,
  error: null,
  status: "idle",
} as ITeamExternalListSlice;

export const getTeamsExternalList = createAsyncThunk(
  "team/getTeamsExternalList",
  teamExternalListAPI.getTeamExternalList
);

const teamExternalListSlice = createSlice({
  name: "teamExternalList",
  initialState: INITIAL_STATE,
  reducers: {
  },
  extraReducers: (builder) => {
    builder
      .addCase(getTeamsExternalList.pending, (state) => {
        state.loading = true;
        state.status = "loading";
      })
      .addCase(getTeamsExternalList.fulfilled, (state, action) => {
        state.loading = false;
        state.error = null;
        state.teamExternalList = action.payload.data;
        state.status = "succeeded";
      })
      .addCase(getTeamsExternalList.rejected, (state, action) => {
        state.loading = false;
        state.teamExternalList = null;
        state.error = action.payload;
        state.status = "failed";
      });
  },
});

export default teamExternalListSlice.reducer;

export const {  } = teamExternalListSlice.actions;
