import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { API_RESPONSE_STATUS } from "../../../constants/common.constant";
import exportService from "../../../api/export/export.api";


interface IExportState {
    responseData: any;
    loading: boolean;
    error: any;
    status: string;
}

const INITIAL_STATE = {
    responseData: null,
    loading: false,
    error: null,
    status: "idle"
} as IExportState;

export const _export = createAsyncThunk(
    "export-excel-function/download-excel",
    exportService.exportAssignmentExcel
);

export const exportSlice = createSlice({
    name: "export",
    initialState: INITIAL_STATE,
    reducers: {
    },
    extraReducers: (builder) => {
        builder
            .addCase(_export.pending, (state) => {
                state.loading = true;
                state.status = API_RESPONSE_STATUS.LOADING;
            })
            .addCase(_export.fulfilled, (state, action) => {
                state.loading = false;
                state.error = null;
                state.responseData = action.payload;
                state.status = API_RESPONSE_STATUS.SUCCESS;
            })
            .addCase(_export.rejected, (state, action) => {
                state.loading = false;
                state.responseData = null;
                state.error = action.payload;
                state.status = API_RESPONSE_STATUS.FAILED;
            })
    },
});