import { ITechnicianItem } from "./../../../api/technician-board/technician-board.model";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import technicianBoardApi from "../../../api/technician-board/technician-board.api";

export const INITIAL_STATE: { response: ITechnicianItem[] } = {
  response: [],
};

export const _getAllTechnician = createAsyncThunk(
  "api/technician",
  technicianBoardApi.getAllTechnician
);

export const getTechnicianListSlice = createSlice({
  name: "technicianListSlice",
  initialState: INITIAL_STATE,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(_getAllTechnician.pending, (state) => {
        state.response = [];
      })
      .addCase(_getAllTechnician.fulfilled, (state, action) => {
        state.response = action.payload.data;
      })
      .addCase(_getAllTechnician.rejected, (state) => {
        state.response = [];
      });
  },
});
