interface Paths {
    [key: string]: string;
 }
 
 export default {
    PLANNING: '/planning',
    ACCESS_DENIED :'/access-denied',
    SCHEDULING:'/scheduling',
    LANDING_SCREEN:'/',
    ADMIN_PORTAL:'/admin',
    TECHNICIAN_BOARD:'/technician-board',
    HEP_HOME:'/hep',
    NOT_FOUND: '*',
 } as Paths;