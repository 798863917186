import { combineReducers } from "@reduxjs/toolkit";
import { searchTaskSlice } from "./searchTaskSlice";
import { getTaskDetailsSlice } from "./getTaskDetailsSlice";
import { getTechnicianListSlice } from "./getTechnicianList";

export const technicianBoardRootReducer = combineReducers({
  searchTask: searchTaskSlice.reducer,
  taskDetails: getTaskDetailsSlice.reducer,
  technicianList: getTechnicianListSlice.reducer,
});
