import httpClient from "../../common/http/httpClient";
import { IAuthService, ILoginRequest, ILoginResponse, IRequestAccess } from "./auth.model";
import { AxiosResponse } from "axios";

const AuthService = (): IAuthService => {
  return {
    login: (request: ILoginRequest): HttpPromise<ILoginResponse> => {
      return httpClient
        .post<ILoginResponse>(
          `${process.env.REACT_APP_API_BASE_URL}/api/authorization/login`,
          { data: request }
        )
        .then((res: AxiosResponse<ILoginResponse, any>) => res);
    },
    requestAccess: (request: IRequestAccess): HttpPromise<any> => {
      return httpClient
        .post<any>(
          `${process.env.REACT_APP_API_BASE_URL}/api/authorization/request-access`,
          { data: request }
        )
        .then((res: AxiosResponse<any, any>) => res);
    },
  };
};

export default AuthService();
