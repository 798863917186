import { createAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import hepScreenApi from "../../../api/hep/hep-screen-api";
import { API_RESPONSE_STATUS } from "../../../constants/common.constant";
import { IActivityItem } from "../../../api/hep/hep-screen-model";

export interface ICreateNewRequestHEP {
    stage: number
    isTriggerContinueButton: boolean,
    isTriggerSaveDraftButton: boolean,
    isEnableContinueButton: boolean,
    status: string,
    loading: boolean,
    isEnableSaveDraftButton: boolean,
    stageDirection: number
}

const INITIAL_STATE = {
    stage: 0,
    isTriggerContinueButton: false,
    isTriggerSaveDraftButton: false,
    isEnableContinueButton: false,
    status: "idle",
    loading: false,
    stageDirection: 1,
    isEnableSaveDraftButton: false
} as ICreateNewRequestHEP;

export const _continueButton = createAction<number>('/hep/saveButton')
export const _saveDraftButton = createAction<number>('/hep/saveDraftButton')
export const _enableContinueButton = createAction<boolean>('/hep/enableContinueButton')
export const _getActivities = createAsyncThunk("hep/activities", hepScreenApi.getActivies);
export const _enableSaveDraftButton = createAction<boolean>('/hep/enableSaveDraftButton')
export const _updateStage = createAction<number>('/hep/_updateStage')

const createNewRequestHEPSlice = createSlice({
    name: "createNewRequestHEP",
    initialState: INITIAL_STATE,
    reducers: {
    },
    extraReducers: (builder) => {
        builder
            .addCase(_continueButton, (state, action) => {
                state.stage = action.payload;
                state.isTriggerContinueButton = !state.isTriggerContinueButton;
            })
            .addCase(_saveDraftButton, (state, action) => {
                state.stage = action.payload;
                state.isTriggerSaveDraftButton = !state.isTriggerSaveDraftButton;
            })
            .addCase(_enableContinueButton, (state, action) => {
                state.isEnableContinueButton = action.payload;
            })
            .addCase(_updateStage, (state, action) => {
                state.stageDirection = action.payload;
            })
            .addCase(_getActivities.pending, (state) => {
                state.status = API_RESPONSE_STATUS.LOADING;
                state.loading = true;
            })
            .addCase(_getActivities.fulfilled, (state, action) => {
                state.status = API_RESPONSE_STATUS.SUCCESS;
                state.loading = false;
            })
            .addCase(_getActivities.rejected, (state, action) => {
                state.status = API_RESPONSE_STATUS.FAILED;
                state.loading = false;
            })
            .addCase(_enableSaveDraftButton, (state, action) => {
                state.isEnableSaveDraftButton = action.payload;
            })
    },
});

export default createNewRequestHEPSlice;