import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { schedulingChartAPI } from "../../../api/service";
import { IScheduleChartDataList } from "../../../api/scheduling-charts/scheduling-charts.model";

export interface IScheduleChartSlice {
  schedulingCharts: IScheduleChartDataList[] | null;
  error: any;
  status: string;
  chartLoading: boolean;
  isPlannedHour: boolean;
  isActualHour: boolean;
}

const INITIAL_STATE = {
  schedulingCharts: null,
  error: null,
  status: "idle",
  chartLoading: false,
  isPlannedHour: true,
  isActualHour: false,
} as IScheduleChartSlice;

export const getScheduleChart = createAsyncThunk(
  "scheduling/getScheduleChart",
  schedulingChartAPI.getScheduleChart
);

export const ScheduleChartSlice = createSlice({
  name: "schedulingChart",
  initialState: INITIAL_STATE,
  reducers: {
    changePlannedHourStatus(state) {
      state.isPlannedHour = !state.isPlannedHour;
    },
    changeActualHourStatus(state) {
      state.isActualHour = !state.isActualHour;
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(getScheduleChart.pending, (state) => {
        state.chartLoading = true;
        state.status = "loading";
      })
      .addCase(getScheduleChart.fulfilled, (state, action) => {
        state.chartLoading = false;
        state.error = null;
        state.schedulingCharts = action.payload.data;
        state.status = "succeeded";
      })
      .addCase(getScheduleChart.rejected, (state, action) => {
        state.chartLoading = false;
        state.schedulingCharts = null;
        state.error = action.payload;
        state.status = "failed";
      });
  },
});

export const {changePlannedHourStatus, changeActualHourStatus} = ScheduleChartSlice.actions
