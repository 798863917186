import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { ISearchTaskSlice } from "../../../api/technician-board/technician-board.model";
import { technicianBoardAPI } from "../../../api/service";

export const INITIAL_STATE = {
  response: null,
  loading: false,
  error: null,
  status: "idle",
} as ISearchTaskSlice;

export const _searchTask = createAsyncThunk(
  "api/technician/searchtask",
  technicianBoardAPI.searchTask
);

export const searchTaskSlice = createSlice({
  name: "searchTask",
  initialState: INITIAL_STATE,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(_searchTask.pending, (state) => {
        state.loading = true;
        state.status = "loading";
      })
      .addCase(_searchTask.fulfilled, (state, action) => {
        state.loading = false;
        state.error = null;
        state.response = action.payload.data;
        state.status = "succeeded";
      })
      .addCase(_searchTask.rejected, (state, action) => {
        state.loading = false;
        state.response = null;
        state.error = action.payload;
        state.status = "failed";
      });
  },
});