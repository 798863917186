import { ICreateTeamRequest, ISearchTeamRequest, ITeamManagementService, IUpdateTeamRequest, ITeamManagementTableData, IUpdateTeamStatusRequest, ISearchTeamManagementRequest } from "./team-management.model";
import HttpClient from "../../common/http/httpClient";
import { AxiosResponse } from "axios";

const teamManagementService = (): ITeamManagementService => {
  return {
    getTeamList: (request: ISearchTeamManagementRequest): HttpPromise<ITeamManagementTableData[]> => {
      return HttpClient
        .post<ITeamManagementTableData[]>(`${process.env.REACT_APP_API_BASE_URL}/api/team-management/search-team`, { data: request })
        .then((res: AxiosResponse<ITeamManagementTableData[], any>) => res);
    },
    createTeam: (reqObj: ICreateTeamRequest) => {
      return HttpClient
        .post(`${process.env.REACT_APP_API_BASE_URL}/api/team-management/create-team`, { data: reqObj })
        .then((res) => res);
    },
    updateTeam: (reqObj: IUpdateTeamRequest) => {
      return HttpClient
        .post(`${process.env.REACT_APP_API_BASE_URL}/api/team-management/update-team`, { data: reqObj })
        .then((res) => res);
    },
    updateTeamStatus: (reqObj: IUpdateTeamStatusRequest) => {
      return HttpClient
        .post(`${process.env.REACT_APP_API_BASE_URL}/api/team-management/update-team-status`, { data: reqObj })
        .then((res) => res);
    },
    deleteTeam: (teamId: string) => {
      return HttpClient
        .post(`${process.env.REACT_APP_API_BASE_URL}/api/team-management/delete-team`, { data: { id: teamId } })
        .then((res) => res);
    },
    checkTeamName: (teamName: string) => {
      return HttpClient
        .get(`${process.env.REACT_APP_API_BASE_URL}/api/team-management/check-team-name/${teamName}`)
        .then((res) => res);
    },
    getSchedulerList: (searchString: string) => {
      return HttpClient
        .get(`${process.env.REACT_APP_API_BASE_URL}/api/team-management/search-scheduler?search-string=${searchString}`)
        .then((res) => res);
    },
    createUpdateTeamMembers: (reqObj: any) => {
      return HttpClient
        .post(`${process.env.REACT_APP_API_BASE_URL}/api/team-management/create-update-team-members`, { data: reqObj })
        .then((res) => res);
    },
    updateTeamDefaultHours: (hourList: any) => {
      return HttpClient
        .post(`${process.env.REACT_APP_API_BASE_URL}/api/team-management/update-team-default-hours`, { data: hourList })
        .then((res) => res);
    },
    updateWeekNonWorkHours: (hourList: any) => {
      return HttpClient
        .post(`${process.env.REACT_APP_API_BASE_URL}/api/team-management/update-week-non-work-hours`, { data: hourList })
        .then((res) => res);
    },
  }
};

export default teamManagementService();