
import httpClient from "../../../common/http/httpClient";
import { IResourceListResponse, IResourceService, ISearchRequest } from "./search-resource.model";

const resourceSearchService = (): IResourceService => {
  return {
    getResourceList: (request: ISearchRequest): HttpPromise<IResourceListResponse> => {
      return httpClient
        .post<IResourceListResponse>(
          `${process.env.REACT_APP_API_BASE_URL}/api/resource-management/search-resource`, ({data: request})
        )
        .then((res) => res);
    },
  };
};

export default resourceSearchService();
